#gallery {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  padding: 50px 0 130px;

  @media (max-width: 991px) {
    column-count: 2;
  }

  @media (max-width: 556px) {
    column-count: 1;
  }
}

#gallery img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
}

.image-viewer-container .styles-module_wrapper__1I_qj {
  z-index: 10;
  backdrop-filter: blur(6px);
  background-color: #000000a8;
}
.filter-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter-mob-wrapper.mobile-only {
  display: none;
}
.filter-wrapper .chips {
  flex: 1 1 auto;
}
.filter-wrapper .chips ul li {
  margin: 0 10px 10px 0;
}
.filter-wrapper .chips ul li a {
  background: rgba(255, 255, 255, 0.8);
  color: #091e30;
  padding: 10px 15px;
  font-size: 12px;
  width: 100%;
  border-radius: 25px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}
#darkMode .filter-wrapper .chips ul li a {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  border: 1px solid var(--gray);
}
.filter-wrapper .chips ul li a.active,
.filter-wrapper .chips ul li a:hover,
#darkMode .filter-wrapper .chips ul li a:hover,
#darkMode .filter-wrapper .chips ul li a.active {
  background: var(--green);
  color: var(--white);
}

.right-sider-filter {
  display: flex;
  align-items: center;
  flex: 0 0 260px;
  justify-content: end;
}
.sort-by span {
  color: var(--white);
}
.sort-by .dropdown {
  display: inline-block;
  vertical-align: middle;
}
.sort-by .dropdown .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  margin: 0;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 0 35px 0 15px;
  display: inline-block;
  position: relative;
  color: var(--white);
  font-weight: 600;
}
.sort-by .dropdown .dropdown-toggle:hover,
.sort-by .dropdown .dropdown-toggle:active,
.sort-by .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--transparent) !important;
  border-color: var(--transparent);
  box-shadow: none;
}
.sort-by .dropdown-toggle::after {
  position: absolute;
  content: "\f107";
  border: none;
  font-family: var(--fontawasome);
  font-weight: 600;
  right: 15px;
  color: var(--white);
}
.sort-by .dropdown-menu {
  min-width: 210px;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 8px;
}
#darkMode .sort-by .dropdown-menu {
  background: #091e30;
}
.sort-by .dropdown-item {
  display: block;
  width: 100%;
  padding: 7px 15px;
  clear: both;
  font-weight: 400;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-weight: var(--fw-bold);
  font-size: var(--default-font-size);
  border-radius: 25px;
}
#darkMode .sort-by .dropdown-item {
  color: var(--gray);
}

.sort-by .dropdown-item:focus,
.sort-by .dropdown-item:hover {
  color: var(--white);
  background-color: var(--pink);
}
#darkMode .sort-by .dropdown-item:focus,
#darkMode .sort-by .dropdown-item:hover {
  color: var(--white);
  background-color: var(--green);
}

.list-grid-toggler a {
  display: inline-block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border: 1px solid #596c7f;
  text-align: center;
  border-radius: 100%;
}

// Mobile View dropdown
.filter-mob-wrapper.mobile-only {
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}
.chips-dropdown .dropdown .dropdown-toggle {
  background-color: rgba(0, 0, 0, 0.4);
  outline: none !important;
  border: none;
  margin: 0;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 12px 40px 12px 25px;
  display: inline-block;
  position: relative;
  color: var(--white);
  font-weight: 600;
  border-radius: 25px;
}
.chips-dropdown .dropdown .dropdown-toggle:hover,
.chips-dropdown .dropdown .dropdown-toggle:active,
.chips-dropdown .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-color: var(--transparent);
  box-shadow: none;
}
.chips-dropdown .dropdown-toggle::after {
  position: absolute;
  content: "\f107";
  border: none;
  font-family: var(--fontawasome);
  font-weight: 600;
  right: 15px;
  color: var(--lightgray);
}
.chips-dropdown .dropdown-menu {
  min-width: 210px;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 8px;
}
#darkMode .chips-dropdown .dropdown-menu {
  background: #091e30;
}
.chips-dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 7px 15px;
  clear: both;
  font-weight: 400;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-weight: var(--fw-bold);
  font-size: var(--default-font-size);
  border-radius: 25px;
}
#darkMode .chips-dropdown .dropdown-item {
  color: var(--gray);
}
.chips-dropdown .dropdown-item:focus,
.chips-dropdown .dropdown-item:hover,
.chips-dropdown .dropdown-item.active {
  color: var(--white);
  background-color: var(--pink);
}
#darkMode .chips-dropdown .dropdown-item:focus,
#darkMode .chips-dropdown .dropdown-item:hover,
#darkMode .chips-dropdown .dropdown-item.active {
  color: var(--white);
  background-color: var(--green);
}

@media only screen and (max-width: 991px) {
  .filter-wrapper.desktop-only {
    display: none;
  }
  .filter-mob-wrapper.mobile-only {
    display: block;
  }
  .filter-mob-wrapper.mobile-only {
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .chips-dropdown .dropdown .dropdown-toggle {
    padding: 8px 20px 8px 10px;
    font-size: 12px;
  }
  .chips-dropdown .dropdown-toggle::after {
    right: 7px;
  }
  .list-grid-toggler a {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
  .sort-by span {
    color: #596c7fbf;
  }
  .sort-by .dropdown-toggle::after {
    color: #596c7fbf;
  }
  .sort-by .dropdown .dropdown-toggle {
    padding: 0 20px 0 10px;
    font-size: 12px !important;
    color: #596c7fbf;
  }
  #result .top-caption h5 {
    color: #596c7fbf !important;
  }
  #darkMode {
    .sort-by span {
      color: var(--white);
    }
    .sort-by .dropdown-toggle::after {
      color: var(--white);
    }
    .sort-by .dropdown .dropdown-toggle {
      color: var(--white);
    }
    #result .top-caption h5 {
      color: var(--white) !important;
    }
  }
  .sort-by .dropdown-toggle::after {
    right: 4px;
  }
}

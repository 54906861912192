#darkMode {
  .traveler-section {
    .text {
      p {
        color: var(--white) !important;
      }
    }
  }
  .range-bar .range-value {
    color: var(--white) !important;
  }
}
.showmore-favorite {
  background-color: white;
  padding-top: 48px;
  margin-top: -24px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  padding-bottom: 15px;
  .dates-feilds {
    display: flex;
    width: 100%;
    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
    .check-in {
      display: flex;
      align-items: center;
      width: 50%;
      @media (max-width: 576px) {
        width: 60%;
      }
    }
  }
  .icon svg {
    font-size: 18px;
    color: var(--darkgray);
    margin-right: 12px;
    display: inline-block;
    margin-top: 2px;
  }
  .destinations {
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      border-left: 1px solid var(--darkgray);
      content: "";
      position: absolute;
      left: -15px;
      width: 0;
      height: 43px;
      @media (max-width: 991px) {
        display: none;
      }
    }
    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }
  .traveler-section {
    @extend .destinations;
    p {
      color: var(--default-color);
    }
  }
  .serach-action {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    cursor: pointer;
    margin-left: -10px;
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      margin-left: -10px;
    }
  }
  .row {
    width: 100%;
    margin: auto;
    padding: 0 16px;
    align-items: center;
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      padding-left: 2px;
    }
  }
  .calender-menu.dropdown-menu.show {
    border: 0;
    padding: 0;
  }
  .calendar-wrapper .rdrDateRangePickerWrapper {
    margin-top: 10px;
    position: absolute;
    transform: translateX(-50%);
    z-index: 3;
    min-height: 400px;
    left: 70%;
    top: 40px;
    @media (max-width: 991px) {
      left: -50%;
    }
  }
  h6 {
    font-size: 17px;
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: 16px;
    }
    @media (max-width: 576px) {
      font-size: 15px;
    }
  }
  p {
    font-size: 13px;
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: 12px;
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .calendar-dropdown {
      bottom: auto;
    }
    .calendar-wrapper .rdrDateRangePickerWrapper {
      left: 50%;
      top: 0;
    }
  }
}

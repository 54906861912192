.accordion.faq-accordion {
  border-bottom: 0;
  margin-bottom: 3px;

  .accordion-body {
    color: var(--default-color);
    font-size: 16px;
    font-family: var(--noto);
    background-color: #00000017;
    padding: 25px;
    backdrop-filter: blur(6px);
    a {
      color: #58b898;
    }
    button {
      background: var(--green);
      color: #fff;
      padding: 5px 16px;
      border-radius: 4px;
      text-align: center;
      border: 0;
      margin-top: 10px;
      // box-shadow: 1px 1px 10px #00000066;
      box-shadow: 0px 5px 20px #05ce7874;
    }
  }
  
  .accordion-button {
    font-size: 16px;
    color: var(--green);
    outline: none !important;
    font-weight: 600;
    padding: 25px;
    background-color: #0000006e;
    backdrop-filter: blur(9px);
  }
}
#darkMode .accordion-body {
  color: var(--gray);
  background-color: #00000066;
}

#result .top-caption {
    margin: 50px 0;
    text-align: center;
    border-bottom: 1px solid #E6E8EC4D;
    padding-bottom: 15px;
}

.main-wrapper.search-page {
    background-image: url(../../assets/images/main-background-light.png),
        url(../../assets/images/blog-background.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover, 0;
}

#result .top-caption h5 {
    color: var(--white);
    font-size: 22px;
}
#result .top-caption h5 span {
    color: var(--green);
}


#result .filter-holder {
    margin-bottom: 50px;
}
.search-tags li {
    margin-right: 5px;
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    color: #091e30;
    padding: 8px 20px 8px 15px;
    font-size: 10px;
    border-radius: 25px;
    font-weight: 600;
    text-align: center;
    border: 1px solid transparent;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    margin-bottom: 5px;
}
.search-tags li .close-icon {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    right: 10px;
    font-size: 10px;
}
.search-tags li a{
    color: #091e30;
}
.search-tags li .fa-star {
    color: #FFC500;
}
.search-tags li.reset-all{
    background-color: #596C7F ;
}
.search-tags li.reset-all a{
    color: #fff;
}
#darkMode .search-tags li{
    background: rgba(17,38,56,1);
    color: #fff;
    border: 1px solid var(--gray);
}
#darkMode .search-tags li a{
    color: #fff;
}
.filter-action{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.filter-action .sort-by span {
  color: var(--lightgray);
}
.filter-action .sort-by .dropdown {
  display: inline-block;
  vertical-align: middle;
}
.filter-action .sort-by .dropdown .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  margin: 0;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 0 35px 0 15px;
  display: inline-block;
  position: relative;
  color: var(--white);
  font-weight: 600;
}
.filter-action .sort-by .dropdown .dropdown-toggle:hover,
.filter-action .sort-by .dropdown .dropdown-toggle:active,
.filter-action .sort-by .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--transparent) !important;
  border-color: var(--transparent);
  box-shadow: none;
}
.filter-action .sort-by .dropdown-toggle::after {
  position: absolute;
  content: "\f107";
  border: none;
  font-family: var(--fontawasome);
  font-weight: 600;
  right: 15px;
  color: var(--lightgray);
}
.filter-action .sort-by .dropdown-menu {
  min-width: 210px;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 8px;
}
#darkMode .filter-action .sort-by .dropdown-menu {
    background: #091E30;
}
.filter-action .sort-by .dropdown-item {
  display: block;
  width: 100%;
  padding: 7px 15px;
  clear: both;
  font-weight: 400;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-weight: var(--fw-bold);
  font-size: var(--default-font-size);
  border-radius: 25px;
}
#darkMode .filter-action .sort-by .dropdown-item {
    color: var(--gray);
  }

  .filter-action .sort-by .dropdown-item:focus,
  .filter-action .sort-by .dropdown-item:hover {
  color: var(--white);
  background-color: var(--pink);
}
#darkMode .filter-action .sort-by .dropdown-item:focus,
#darkMode .filter-action .sort-by .dropdown-item:hover {
  color: var(--white);
  background-color: var(--green);
}

.list-grid-toggler a {
  display: inline-block;
  width: 43px;
  height: 43px;
  line-height: 37px;
  border: 1px solid #596c7f;
  text-align: center;
  border-radius: 100%;
}
.collapsable-filter-wrapper {
    padding: 15px;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    border-radius: 13px;
    .range-bar .caption p {
        color: var(--default-color);
        span {
            color: var(--default-color);
        }
    }
    .range-bar .range-value {
        color: var(--default-color);
    }
}
#darkMode .collapsable-filter-wrapper {
    background: rgba(17,38,56,0.6);
    backdrop-filter: blur(6px);
    border-radius: 13px;
    border: 1px solid var(--gray);
    .range-bar .caption p {
        color: var(--white);
        span {
            color: var(--white);
        }
    }
    .range-bar .range-value {
        color: var(--white);
    }
}
.collapsable-block {
    border-bottom: 1px solid var(--gray);
    padding: 20px 0;
}
.collapsable-block h6{
    margin-bottom: 15px;
    color: var(--default-color);
}
#darkMode .collapsable-block h6{
    color: var(--green);
}
.collapsable-block .form-group{
    position: relative;
    margin-bottom: 7px;
}
.collapsable-block input {
    background-color: transparent;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
    border: 1px solid var(--gray);
    box-shadow: none;
    padding: 9px 15px 9px 30px;
    outline: none;
    font-family: var(--noto);
}
#darkMode .collapsable-block input{
    background-color: #112638;
    color: #fff;
}
.collapsable-block .floating-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}
.collapsable-block .form-group:last-child{
    margin-bottom: 0;
}
.form-group .dropdown .dropdown-toggle {
    background-color: transparent;
    outline: none !important;
    border: none;
    margin: 0;
    font-size: var(--default-font-size);
    box-shadow: none !important;
    padding: 9px 15px;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    color: var(--default-color);
    border: 1px solid var(--gray);
    width: 100%;
    text-align: left;
}
.form-group .dropdown .dropdown-toggle:hover,
.form-group .dropdown .dropdown-toggle:active,
.form-group .dropdown .dropdown-toggle:focus {
    color: var(--default-color);
    background-color: transparent !important;
    border: 1px solid var(--gray);
    box-shadow: none;
  }
  .form-group  .dropdown-toggle::after {
    position: absolute;
    content: "\f107";
    border: none;
    font-family: var(--fontawasome);
    font-weight: 600;
    right: 15px;
    color: var(--gray);
}
.form-group .dropdown-menu {
    min-width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    border-radius: 8px;
    border: 1px solid var(--gray);
}
.form-group .dropdown-item {
    display: block;
    width: 100%;
    padding: 10px 15px;
    clear: both;
    font-weight: 400;
    color: var(--default-color);
    text-align: inherit;
    text-decoration: none;
    border: 0;
    font-family: var(--noto);
    font-weight: var(--fw-bold);
    font-size: var(--default-font-size);
    border-radius: 25px;
  }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    background-color: var(--green) !important;
}
//   .dropdown-with-checkbox.form-group .dropdown-item{
//     padding: 10px 0;
//   }
  .form-group .dropdown-item:active,
  .form-group .dropdown-item:focus,
  .form-group .dropdown-item:hover {
    color: var(--white);
    background-color: var(--pink);
  }
  .form-group .dropdown-item img,
  .form-group .dropdown-toggle img{
      margin-right: 7px;
  }

  .form-group .dropdown-toggle::after {
    top: 50%;
    transform: translateY(-50%);
}


.dropdown-with-checkbox.form-group .dropdown-item:focus,
.dropdown-with-checkbox.form-group .dropdown-item:hover {
    color: var(--default-color);
    background-color: transparent;
  }

#darkMode .form-group .dropdown-menu {
    background: #112638;
}
#darkMode .form-group .dropdown-item {
    color: #C4CDD5;
}
#darkMode .collapsable-block input,
#darkMode .form-group .dropdown .dropdown-toggle,
#darkMode .form-group .dropdown .dropdown-toggle:hover,
#darkMode .form-group .dropdown .dropdown-toggle:active,
#darkMode .form-group .dropdown .dropdown-toggle:focus{
    background-color: #112638 !important;
    color: #fff;
}
#darkMode .form-group .dropdown-item:focus,
#darkMode .form-group .dropdown-item:hover {
  color: var(--white);
  background-color: var(--green);
}


// Mobile  Filter 
.show-on-small-screen{
    display: none;
}



.filter-mob-wrapper {
    justify-content: space-between;
    align-items: center;
    display: none;
}
.filter-mob-wrapper .filter-toggles{
    position: relative;
}
.filter-mob-wrapper .filter-toggles a{
    background-color: var(--green);
    color: var(--white);
    font-size: 12px;
    padding: 6px 18px;
    border-radius: 25px;
}
.filter-mob-wrapper .filter-toggles a img{
    margin-right: 10px;
}
.filter-toggles .count {
    position: absolute;
    background-color: var(--blue);
    color: var(--white);
    font-size: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
    top: -5px;
    right: -5px;
}

.sidebar-dropdown {
    .dropdown-item {
        font-size: 13px !important;
    }
    .dropdown-menu {
        padding: 7px;
    }
    .dropdown-toggle {
        display: flex !important;
        align-items: center;
        gap: 5px;
        span {
            width: 80%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    } 
} 
.filter-header .filter-title-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
    padding: 15px 0;
}
.filter-header .filter-title-area h6{
    font-size: 18px;
    color: var(--default-color);
}
.filter-header .filter-title-area .close-icon,
.filter-header .filter-title-area .delete-all-link{
    color: var(--gray);
}
.filter-header .filter-title-area .close-icon svg {
    font-size: 16px;
}
#darkMode .filter-header .filter-title-area h6{
    color: var(--white);
}

#darkMode .filter-header .filter-title-area .close-icon,
#darkMode .filter-header .filter-title-area .delete-all-link
 {
    color: var(--white);
}
.filter-header .filter-title-area h6 .count {
    background-color: var(--blue);
    color: var(--white);
    font-size: 12px;
    display: inline-block;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    border-radius: 100%;
    vertical-align: middle;
}
.filter-header .search-tags{
    padding: 25px 0;
}

#darkMode #result .accordion-body {
    background-color: transparent;
}
#result .accordion-body {
    background-color: transparent;
}

// Pagination 

.custom-pagination{
    text-align: center;
    margin: 50px 0;
    @media only screen and (max-width:991px){
        margin: 0 0 50px;
    }
}



@media only screen and (max-width:991px){
    .hide-on-small-screen{
        display: none;
    }
    .show-on-small-screen{
        display: flex;
        &.filter-mob-wrapper {
            .powered-by-text {
                margin: 0 auto;
            }
        }
    }
    .quick-search-wrapper .left-action-back-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .filter-header.show-on-small-screen {
        display: block;
    }
    #result .top-caption h5 {
        font-size: 18px;
    }
    .collapsable-filter-wrapper{
        opacity: 0;
        visibility: hidden;
        padding: 15px;
        border: 1px solid transparent;
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        z-index: 100;
        overflow: auto;
        border-radius: 0;
        transition: var(--smooth);
    }
    .collapsable-filter-wrapper.open-filter {
        opacity: 1;
        visibility: visible;
    }
    #darkMode .collapsable-filter-wrapper.open-filter{
        background: rgba(0, 0, 0, 0.6);
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(10px);
        border-radius: 0;
        border: 1px solid var(--gray);
    }
    .search-tags li {
        border: 1px solid var(--gray);
    }
}

@media only screen and (max-width: 576px){
    #result .top-caption h5 {
        font-size: 14px;
    }
}

.OrDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    text-transform: uppercase;
}

#darkMode .OrDiv {
    color: #fff;
}


// Updations

.collapsable-filter-wrapper .accordion {
    border-bottom: none;
    margin-bottom: 0;
}
.collapsable-filter-wrapper .accordion-body {
    padding: 0;
}
.loaction-tag {
    margin-top: 15px;
}
.loaction-tag li {
    margin-right: 5px;
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    color: #091e30;
    padding: 7px 15px;
    font-size: 10px;
    border-radius: 25px;
    font-weight: 600;
    text-align: center;
    border: 1px solid transparent;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    margin-bottom: 7px;
    border: 1px solid var(--gray);
}
#darkMode .loaction-tag li{
    background: #112638;
    color: #fff;
    border: 1px solid var(--gray);
}
.loaction-tag li span {
    position: absolute;
    right: 0;
    background: var(--green);
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    top: -5px;
    right: -5px;
    cursor: pointer;
}

.filters-sidebar-btn {
    position: sticky;
    bottom: 10px;
}
.apply-filter-btn{
    background: var(--green);
    color: #fff;
    padding: 10px 10px;
    display: block;
    border-radius: 4px;
    text-align: center;
    border: none;
    margin-top: 25px;
    width: 100%;
    box-shadow: 0px 5px 20px #05ce7874;
}

#darkMode .collapsable-block .floating-icon {
   color:#fff
}
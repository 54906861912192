#detailsContent {
    max-width: 1060px;
    margin: 0 auto 50px;
}
#detailsContent .content-holder .subtitle {
    background-color: var(--orange);
    display: inline-block;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 6px;
    margin-bottom: 5px;
    color: var(--white);
    font-family: var(--noto);
    font-size: 14px;
}
.showmore-btn {
    padding: 10px 16px;
    border-radius: 25px;
    display: inline-block;
    position: relative;
    color: var(--black);
    border: 1px solid var(--black);
    text-align: left;
    font-family: var(--noto);
    font-size: 13px;
    background-color: var(--transparent);
    outline: none !important;
    // margin-bottom: 30px;
}
#darkMode {
    .showmore-btn {
        color: #BDC6D7;
        border: 1px solid #BDC6D7;;
    }
}
#detailsContent .content-holder .subtitle img{
    margin-right: 10px;
}
#darkMode #detailsContent .content-holder h2{
    color: var(--white);
}
#detailsContent .content-holder p{
    font-size: 16px;
    font-family: var(--noto);
    margin: 0 0 25px;
    color: var(--black);
    text-shadow: 0px 0px var(--black);
}
#detailsContent .content-holder p:last-child{
    margin: 0;
}
#darkMode #detailsContent .content-holder p{
    color: #BDC6D7;
}
#detailsContent .content-holder .more-info {
    display: flex;
    // align-items: center;
    margin: 10px 0px 15px;
}
#detailsContent .content-holder .more-info .stars {
    margin-top: 7px;
  }
  #detailsContent .content-holder .more-info .star svg {
    margin-right: 5px;
    font-size: 13px;
  }
  #detailsContent .content-holder .more-info .star svg.filled {
    color: #ffc500;
  }
  #detailsContent .content-holder .more-info .star svg.blank {
    color: #BDC6D7;
  }
  #detailsContent .content-holder .more-info span {
    color:var(--default-color);
    font-size: 13px;
    font-family: var(--poppings);
  }
  #detailsContent .content-holder .more-info .location {
    color: var(--default-color);
    font-family: var(--poppings);
    margin: 0 0 0 15px;
    font-size: 13px;
}
#detailsContent .content-holder .more-info .location svg {
    margin-right: 5px;
    font-size: 16px;
}
#darkMode #detailsContent .content-holder .more-info .location,
#darkMode #detailsContent .content-holder .more-info span{
    color: #BDC6D7;
}
.features-deatils {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 50px;
}
#detailsContent.sidecontent {
    margin: 50px 0;
}
#detailsContent .content-holder .features-deatils h6 {
    flex: 0 0 100%;
    width: 100%;
    font-size: 22px;
    margin-bottom: 15px;
}
#darkMode #detailsContent .content-holder .features-deatils h6 {
    color: var(--green);
}
#detailsContent .content-holder .features-deatils li {
    // flex: 0 0 50%;
    // width: 50%;
    font-size: 16px;
    color: var(--black);
    padding-bottom: 15px;
    font-family: var(--noto);
    text-shadow: 0px 0px var(--black);
}
#darkMode #detailsContent .content-holder .features-deatils li {
    color: #BDC6D7;
}
#detailsContent .content-holder .features-deatils li {
    position: relative;
    padding-left: 15px;
}
#detailsContent .content-holder .features-deatils li:nth-child(even) {
    padding-right: 15px;
}
#detailsContent .content-holder .features-deatils li::before {
    content: "";
    position: absolute;
    background-color: var(--gray);
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
}
#detailsContent .content-holder .features-deatils li:nth-child(3n + 2)::before {
    content: "";
    position: absolute;
    background-color: var(--gray);
    top: 0;
    left: 0;
    width: 0px;
    height: 100%;
}

.steps-box.mobile-steps {
    display: none;
}

@media only screen and (max-width: 991px){
    #detailsContent .content-holder .subtitle {
    padding: 5px 15px;
    }
    #detailsContent .content-holder h2 {
    font-size: 28px;
    word-break: break-word;
    }
    #detailsContent .content-holder p {
        font-size: 14px;
    }
    #detailsContent .content-holder .features-deatils li {
        font-size: 14px;
        padding-bottom: 10px;
        word-break: break-word;
    }
    .features-deatils {
        margin-top: 20px;
    }
    #detailsImage {
        margin: 30px 0;
    }
    #detailsContent.sidecontent {
        margin: 0;
    }
}

@media only screen and (max-width: 767px){
    #detailsContent .content-holder p {
        margin: 0 0 15px;
    }
    #detailsContent .content-holder .more-info {
        margin: 5px 0px 15px;
    }
    .features-deatils {
        margin-top: 30px;
    }
    #detailsContent .content-holder .features-deatils li::before {
        width: 0;
    }
    .steps-box {
        display: none;
    }
    .steps-box.mobile-steps {
        display: block;
        margin: unset;
        filter: none;
        background: transparent;
        width: 100%;
        height: 70px;
        padding: 27px 30px 0;
        margin-top: 30px;
        .step {
            max-width: min-content;
            text-align: center;
        }
        &::before, &::after {
            display: none;
        }
        .RSPBstep {
            margin-top: 30px;
            .step-label {
                margin-top: 5px !important;
                color: var(--default-color) !important;
            }
            &:nth-last-child(2) {
                .step {
                    max-width: max-content;
                    .step-label {
                        max-width: 70px;
                    }
                } 
            } 
        }
    }

    #darkMode .steps-box.mobile-steps {
        .RSPBstep {
            .step-label {
                color: var(--white) !important;
            }
        }
    }
}






#result .for-mobile{
    display: none;
}
@media only screen and (max-width: 767px){
    #result .for-desktop{
        display: none;
    }
    #result .for-mobile{
        display: block;
    }

}

#packages .list-card-custom-wrapper{
    max-width: 1230px;
    margin: auto;
    padding: 0 15px;
}
#packages .default-list-card{
    margin-top: 40px;
}
// #packages .list-card-pricing:before {
//     display: none;
// }

#textSection {
    position: relative;
    // margin: 50px 0 130px;
}
#textSection .Content-holder {
    max-width: 900px;
    margin: auto;
}
#textSection .title {
    margin-bottom: 30px;
}
#textSection .title .subtitle{
    font-size: 22px;
    color: var(--gray);
    font-weight: 600;
}
#textSection .description {
    margin-bottom: 50px;
}
#darkMode #textSection .description p {
    color: var(--gray);
}
#textSection .description .subtitle{
    margin-bottom: 15px;
    color: var(--green);
}
#textSection .description p {
    margin: 0 0 15px;
}
#textSection .description p a{
    color: var(--black);
    font-family: var(--noto);
}
#textSection .cat-icon {
    display: inline-block;
    position: relative;
    margin-bottom: -45px;
}
#darkMode #textSection .title h2 {
    color: var(--white);    
}
#darkMode #textSection .description p a{
    color: var(--white);
}

@media only screen and (max-width: 991px){
    #textSection .cat-icon {
        max-width: 150px;
    }
}

#textSection .description:last-child{
    margin-top: 0;
}
.image-width-text-wrapper {
    max-width: 990px;
    margin: auto;
}
.image-width-text-wrapper .content-holder h6{
    margin: 15px 0;
    font-size: 18px;
    font-weight: 600;
}
#darkMode .image-width-text-wrapper .content-holder h6{
    color: var(--white);
}
.image-width-text-wrapper .content-holder h6:first-child{
    margin-top: 0;
}
.image-width-text-wrapper .content-holder p{
    margin: 0 0 50px;
    font-family: var(--noto);
    font-size: 16px;
    color: var(--gray) 
}
.image-width-text-wrapper .content-holder p:last-child{
    margin-bottom: 0;
}
.image-width-text-wrapper .image-holder img {
    width: 100%;
}
@media only screen and (max-width: 991px){
    .image-width-text-wrapper {
        max-width: 570px;
        margin: auto;
    }
    .image-width-text-wrapper .image-holder {
        height: 280px;
        margin-bottom: 50px;
        overflow: hidden;
        border-radius: 10px;
        overflow: hidden;
    }
    .image-width-text-wrapper .image-holder img {
        height: 100%;
        object-fit: cover;
    }
}
@media only screen and (max-width: 767px){
    .image-width-text-wrapper .container{
        padding: 0;
    }
    .image-width-text-wrapper .row{
        margin: 0;
    }
    .image-width-text-wrapper .image-holder {
        height: 190px;
        margin-bottom: 30px;
    }
}
.globe-card-header {
  overflow: hidden;
  height: 260px;
  position: relative;
  border-radius: 20px;
}
.globe-card-header img {
    height: 100%;
    object-fit: cover;
    transition: var(--smooth);
    width: 100%;
}
.globe-card:hover .globe-card-header img {
  transform: scale(1.1);
}
.globe-card-header .text {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.globe-card-header .text p {
  margin: 7px 0 0;
}
.globe-card-body .card-items li {
  border-bottom: 1px solid #91bcf2;
  width: 100%;
  padding: 10px 0;
}
#darkMode .globe-card-body .card-items li {
    border-bottom: 1px solid #777E90;
    color: var(--lightgray);
  }
  @media only screen and (max-width: 991px){
    .globe-card-header {
        overflow: hidden;
        height: 160px;
        position: relative;
        border-radius: 10px;
    }
    .globe-card-header .text {
        bottom: 10px;
        left: 10px;
    }
    .globe-card .text h6{
        font-size: 18px;
    }
}
@media only screen and (max-width: 767px){
    
    .globe-card-body {
        padding: 0;
    }
    .globe-card-body .card-items li {
        width: auto;
        display: block;
        max-width: 70%;
    }
    .globe-card-body .card-items li:last-child{
        border: none;
    }
}
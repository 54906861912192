.Demo__container {
  display: flex;
  grid-template-columns: repeat(auto-fill, 30px);
  margin: 8px auto;
  justify-content: space-evenly;
}

.Demo__some-network {
  text-align: center;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.header-for-mobile{
    display: none;
}
header .navbar {
  padding: 15px 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: 10;
  background-color: var(--transparent);
  align-items: flex-start;
}
header .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  font-size: 0;
  text-decoration: none;
  white-space: nowrap;
  border-right: 1px solid var(--gray);
  max-width: 165px;
  flex: 0 0 165px;
}
header .navbar .menu-toggler {
  border: none;
  display: none;
}
header .navbar .menu-toggler span {
  display: block;
  width: 35px;
  height: 2px;
  background-color: var(--green);
  margin: 7px 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
#darkMode header .navbar .menu-toggler span {
  background-color: var(--white);
}
header .navbar .menu-toggler.open-menu .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-5px, 5px);
}
header .navbar .menu-toggler.open-menu .bar2 {
  opacity: 0;
}
header .navbar .menu-toggler.open-menu .bar3 {
  -webkit-transform: rotate(45deg) translate(-9px, -10px);
  transform: rotate(45deg) translate(-7px, -7px);
  width: 30px;
}
header .navbar .menu-toggler:focus {
  outline: none;
}

header .navbar .custom-menu {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .navbar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
header .navbar .menu-link {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 10px 25px;
  border-radius: 25px;
  display: inline-block;
  position: relative;
  color: var(--white);
}

header .navbar .menu-link:hover {
  background-color: var(--pink);
}
header .navbar .menu-link .icon,
header .dropdown-toggle .icon {
  margin-right: 10px;
}

// Header dropdowns

header .dropdown .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  margin: 0;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 10px 25px;
  border-radius: 25px;
  display: inline-block;
  position: relative;
  color: var(--white);
}
header .dropdown .dropdown-toggle:hover,
header .dropdown .dropdown-toggle:active,
header .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--transparent) !important;
  border-color: var(--transparent);
  box-shadow: none;
}
header .dropdown-toggle::after {
  position: absolute;
  content: "\f107";
  border: none;
  font-family: var(--fontawasome);
  font-weight: 600;
  right: -10px;
  color: var(--lightgray);
}
header .dropdown-menu {
  min-width: 260px;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 8px;
}
header .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 15px;
  clear: both;
  font-weight: 400;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-weight: 400;
  font-size: var(--default-font-size);
  border-radius: 25px;
  &.active {
    font-weight: 600;
  }
}
#darkMode header .dropdown-menu {
  color: var(--white);
  background-color: var(--default-color);
}
#darkMode header .dropdown-menu .dropdown-item {
  color: var(--lightgray);
  &.active {
    color: var(--white);
  }
}
header h6 {
  padding: 5px 15px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 600;
}
header .dropdown-item:focus,
header .dropdown-item:hover {
  color: var(--white);
  background-color: var(--pink);
}
header .language-dropdown .dropdown-toggle::after,
header .user-dropdown .dropdown-toggle::after {
  display: none;
}
header .user-dropdown .dropdown .dropdown-toggle {
  padding: 0 25px;
}
header .user-dropdown .dropdown .dropdown-toggle img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.discover-dropdown {
  padding-left: 25px;
}
a.btn-holder {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0;
}
.btn-holder .auth-action{
    flex: 0 0 50%;
    max-width: 50%;
}

.btn-holder .auth-action:first-child{
    padding-right: 7px;    
}
.btn-holder .auth-action:last-child{
    padding-left: 7px;    
}
.btn-holder .auth-action a {
    padding: 10px;
    text-align: center;
    font-family: var(--noto);
    font-size: 13px;
    color: var(-white);
    background-color: var( --blue);
    color: var( --white);
    width: 100%;
    border-radius: 25px;
    border: 1px solid var(--blue)
}

.btn-holder .auth-action a.transparent-btn{
    background-color: transparent;
    border: 1px solid var(--gray);
    color: var(--gray);
}
.btn-holder .auth-action a:hover,
.btn-holder .auth-action a.transparent-btn:hover{
    background-color: var(--gray);
    border: 1px solid var(--gray);
    color: var(--white);
}
header .dropdown-item.btn-holder:focus, header .dropdown-item.btn-holder:hover {
    color: inherit;
    background-color: transparent !important;
}

@media only screen and  (max-width: 991px){
    .header-for-desktop{
        display: none;
    }
    .header-for-mobile{
        display: block;
    }
    .discover-dropdown {
        padding-left: 0;
    }

    header .user-dropdown .dropdown .dropdown-toggle {
      padding: 0;
  }
  header .navbar-brand {
    border-right: none;
    max-width: 100px;
    flex: 0 0 100px;
}
  header .navbar .menu-toggler {
      border: none;
      display: block;
    }
    header .navbar .custom-menu-small {
      background: #091E30;
      position: fixed;
      width: 300px;
      height: 100%;
      top: 0;
      z-index: 9;
      opacity: 0;
      visibility: hidden;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      left: -300px;
      transition: all 0.3s ease 0s;
      transform: translateX(-300px);
      overflow: auto;
  }
  header .navbar .custom-menu-small.show{
    opacity: 1;
    visibility: visible;
      left: 0px;
      transform: translateX(0px);
  }
  header .custom-menu-small .close-menu {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 24px;
    color: #fff;
  }
  header .navbar .custom-menu-small ul {
    display: block;
    margin: 70px 0;
  }
  header .navbar .custom-menu-small ul li{
      width: 100%;
  }
  header .navbar .custom-menu-small ul li .menu-link {
    font-size: 22px;
    font-weight: 400;
    width:100%;
    padding:15px;
  }
  header .navbar .custom-menu-small ul li .menu-link:hover,
  header .navbar .custom-menu-small ul li .menu-link.active {
    background-color: transparent;
    color: var(--green);
  }
  header .custom-menu-small .discover-dropdown  .dropdown-toggle {
    font-size: 22px;
    padding: 15px;
    width: 100%;
    text-align: left;
  }
  header .custom-menu-small .discover-dropdown .dropdown-toggle::after {
    right: 15px;
    color: #fff;
  }

  header .custom-menu-small .dropdown-menu {
    background-color: #091E30;
  }
  header .custom-menu-small .dropdown-item {
    text-decoration: none;
    background-color: transparent;
    border: 0;
    font-family: var(--noto);
    font-weight: 400;
    font-size: 15px;
    border-radius: 0;
    padding: 5px;
    color: var(--gray);
  }
  header .custom-menu-small .discover-dropdown .dropdown-menu {
    min-width: 100%;
    padding: 0 20px 20px;
    text-align: left;
    border: none;
    border-radius: 0;
    position: static !important;
    width: 100%;
    transform: translate3d(0,0,0) !important;
  }
  .small-header-action {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  header .small-header-action .action-holder{
    padding-bottom: 15px;
  }
  header .small-header-action .currancy-dropdown.action-holder{
    padding-left: 15px;
  }
  header .small-header-action .dropdown .dropdown-toggle {
    padding: 10px 25px;
    border-radius: 25px;
    color: var(--white);
    border: 1px solid var(--gray);
    font-size: 14px;
    font-family: var(--noto);
  }
  header .small-header-action .dropdown-toggle::after {
    display: none;
  }

  header .small-header-action .dropdown-menu {
    min-width: 220px;
    border: 1px solid var(--gray);
}
  header .small-header-action .action-holder .darkMode-btn{
    background-color: var(--transparent);
    font-size: 14px;
    padding: 10px 25px;
    border-radius: 25px;
    font-family: var(--noto);
    color: var(--white);
    border: 1px solid var(--gray);
  }
  header .small-header-action .action-holder .darkMode-btn .icon,
  header .custom-menu-small .dropdown-toggle .icon,
  header .custom-menu-small .dropdown-toggle span{
    margin-right: 7px;
    color: var(--gray);
  }
}
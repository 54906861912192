#user-tabs {
    padding: 100px 0 130px;
    position: relative;
    z-index: 4;
}
.tab-controls {
    background-color: rgba(0,0,0,0.7);
    padding: 50px 25px;
    border-radius: 10px;
    backdrop-filter: blur(6px);
}
.tab-controls .nav-item {
    margin-bottom: 20px;
}
.tab-controls .nav-item:last-child {
    margin-bottom: 0;
}
.tab-controls .nav-item a {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: var(--gray);
    padding: 10px 0;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--noto);
}
.tab-controls .nav-item:first-child a{
    padding-top: 0;
}
.tab-controls .nav-item:last-child  a{
    padding-bottom: 0;
}
.tab-controls .nav-item a.active, .tab-controls .nav-item .show>a {
    color: var(--white);
    background-color: transparent;
}
.tab-controls .nav-item a svg {
    margin-right: 15px;
}

@media only screen and (max-width:1199px){
    .tab-controls {
      padding: 30px 15px;
    }
    .tab-controls .nav-item {
        margin-bottom: 5px;
    }
    .tab-controls .nav-item a {
        font-size: 14px;
    }
    .tab-controls .nav-item a svg {
        margin-right: 5px;
    }
}
@media only screen and (max-width:991px){
    #user-tabs {
        padding: 30px 0 130px;
        position: relative;
        z-index: 4;
    }
    .tab-controls {
        padding: 20px;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;
        border-radius: 100px;
        margin-bottom: 40px;
    }
    .tab-controls .nav-item {
        margin-bottom: 0;
    }
    .tab-controls .nav-item a {
        padding: 0;
    }
}
@media only screen and (max-width:576px){
    .tab-controls {
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow: auto;
        gap: 16px;
    }
    .tab-controls .nav-item {
        max-width: fit-content;
        flex: 0 0 130px;
    }
    .tab-controls .nav-item a svg {
        margin-right: 2px;
    }
}
.main-wrapper {
  //background-image: url(../../assets/images/main-background-light.png),
  //  url(../../assets/images/main-background-dark.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover, 0;
}

#darkMode .main-wrapper {
  background-size: 0, cover;
}

.top-bar-container {
  z-index: 1;
  position: absolute;
}

.marker-container {
  div {
    background-color: var(--orange);
    padding: 3px 8px;
    border-radius: 16px;
    color: var(--white);
    font-family: var(--poppings), serif;
    width: max-content;
    text-align: center;
    font-size: var(--fs-15);
    position: absolute;
    bottom: 5px;
    left: 20px;
    border-bottom-left-radius: 0px;
  }
}

.map-wrapper {
  width: calc(100% - 320px);
  @media (max-width: 767px) {
    width: 100%;
  }
}

.mobile-sec-map {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
  .slick-slide.slick-active.slick-center {
    margin-top: -15px;
  }
  .packages-card-grid {
    display: flex;
    .packages-card-header {
      height: 145px;
      width: 35%;
      .card-badge {
        left: -60px;
        top: 2px;
        width: 175px;
        img {
          width: 30px;
        }
    }
    }
    .card-middle-area {
      width: 65%;
      .packages-card-Body {
        height: 145px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
          font-size: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;
        }
      }
    }
  }
}
.map-page {
  .hotels-container {
    width: 320px;
    overflow: auto;
    height: 100vh;
    padding: 16px;
    &.map-desktop {
      .price-card {
        color: var(--blue);
        font-size: 24px;
        font-weight: 500;
        text-align: right;
      }
      .un-selected-btn {
        font-size: 13px;
        color: #fff;
        background-color: var(--gray);
        padding: 12px 15px;
        border-radius: 6px;
        max-width: 100px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-family: var(--noto);
        cursor: pointer;
      }
      .selected-btn {
        font-size: 13px;
        color: #fff;
        background-color: var(--green);
        padding: 12px 15px;
        border-radius: 6px;
        max-width: 100px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-family: var(--noto);
        cursor: pointer;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }

    .packages-card-grid {
      margin-bottom: 16px;
    }
  
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--green);
      border-radius: 30px;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

}

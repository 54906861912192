#papularArea{
    padding: 70px 0 ;
}
#papularArea .title{
    margin-bottom: 50px;
}
#darkMode #papularArea .title h2{
    color: var(--white);
}
.papular-area-wrapper ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.papular-area-wrapper ul li {
    flex: 0 0 20%;
    padding: 0 15px;
    max-width: 20%;
}
.papular-area-wrapper ul li a {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease 0s;
    display: block;
    width: 100%;
}
.papular-area-wrapper ul li a .text {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
.papular-area-wrapper ul li a .text span {
    font-size: 10px;
    font-family: var(--noto);
    color: var(--white);
}
.papular-area-wrapper ul li a .text p {
    font-size: 18px;
    color: var(--white);
    margin: 0;
    font-weight: 600;
}
.papular-area-wrapper ul li a img{
   transition: var(--smooth);
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.papular-area-wrapper ul li a:hover img{
    transform: scale(1.1);
 }

 @media only screen and (max-width:991px){
    #papularArea {
        padding: 30px 0;
    }
    .papular-area-wrapper ul {
        margin: 0 -7px;
    }
    .papular-area-wrapper ul li {
        flex: 0 0 33.3333%;
        padding: 0 7px;
        max-width: 33.3333%;
        margin-bottom: 15px;
    }
    .papular-area-wrapper ul li a .text p {
        font-size: 16px;
    }
 }
 @media only screen and (max-width:767px){
    .papular-area-wrapper ul li {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .papular-area-wrapper ul li a .text span {
        font-size: 8px;
    }
    .papular-area-wrapper ul li a .text p {
        font-size: 12px;
    }
 }

.accordion-item {
    background-color: transparent;
    border: none;
}

.collapsible-filter .accordion-header {
    margin-bottom: 16px;
}

.accordion-button {
    padding: 0;
    font-size: 14px;
    color: var(--green);
    background-color: transparent;
    outline: none !important;
    font-weight: 500;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    color: var(--default-color);
    box-shadow: none;
}

.accordion-button:focus {
    border-color: transparent;
    color: var(--green);
    box-shadow: none;
}

.accordion-button::after {
    width: 15px;
    height: 15px;
    background-size: 15px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2305ce78'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2305ce78'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}

.accordion-body {
    padding: 15px 0 0;
}

#darkMode .accordion-button h6,
.accordion-button:not(.collapsed) {
    color: var(--green);
}
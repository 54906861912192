.flipClock {
  display: flex;
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 42px;
  height: 60px;
  margin-right: 5px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 10px 10px -10px grey;
}

.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid whitesmoke;
}

.upperCard span, .lowerCard span {
  font-size: 5em;
  font-family: "Droid Sans Mono", monospace;
  font-weight: lighter;
  color: #333333;
}

.upperCard {
  align-items: flex-end;
  border-top: 1px solid gray;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}

.upperCard span {
  transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 1px solid gray;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCard span {
  font-family: "Droid Sans Mono", monospace;
  font-size: 5em;
  font-weight: lighter;
  color: #333333;
}

.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid whitesmoke;
  border-top: 1px solid gray;
}

.flipCard.unfold span {
  transform: translateY(-50%);
}

.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 0.5px solid whitesmoke;
  border-bottom: 0.5px solid whitesmoke;
}

.flipCard.fold span {
  transform: translateY(50%);
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@-webkit-keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.mapcards-slider {
    position: absolute;
    width: 100%;
    bottom: 0;
    .packages-card-grid {
        margin-bottom: 0;
    }
    .price-card {
        color: var(--blue);
        font-size: 24px;
        font-weight: 500;
        text-align: right;
    }
    .card-middle-area {
        mask: none;
    }
}
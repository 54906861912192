.room-information {
  position: relative;
  padding-bottom: 50px;
  background: transparent linear-gradient(180deg, #FD8B00 0%, #0f1d2f00 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 30px;
  width: 96%;
  height: calc(100% - 73px);
  @media (max-width:991px) {
    height: auto;
    margin-bottom: 30px;
  }
  .title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0 30px;
    gap: 20px;
    img {
      width: 33px;
      margin-top: 10px;
    }
  }
}
.room-information h5 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 36px;
  color: var(--white);
}
.room-information h5 span {
  color: var(--white);
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}
#darkMode {
  .room-information h5 {
    color: var(--white);
  }
  .room-information h5 span {
    color: var(--white);
  }
} 

.room-card-slider-wrapper .slick-arrow {
  border: 1px solid var(--default-color);
  background-color: transparent;
  color: var(--default-color);
  bottom: -39px;
  cursor: pointer;
}

.room-card-slider-wrapper .slick-arrow:hover {
  border: 1px solid var(--green);
}

#darkMode .room-card-slider-wrapper .slick-arrow {
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.2);
}

#darkMode .room-card-slider-wrapper .slick-arrow:hover {
  border: 1px solid var(--green);
}

.room-card-slider-wrapper {
  padding: 0 30px;
  .slick-slide.slick-active.slick-current {
    transform: translateY(-32px);
    margin-top: 0 !important;
    opacity: 1;
    + .slick-slide {
      -webkit-mask: linear-gradient(90deg, #000, #0000, #0000, #0000, #0000);
      mask: linear-gradient(90deg, #000, #0000, #0000, #0000, #0000);
    }
  }
  .slick-slide {
    &:has(+ .slick-slide.slick-active.slick-current) {
      -webkit-mask: linear-gradient(90deg, #0000, #0000, #0000, #0000, #000);
      mask: linear-gradient(90deg, #0000, #0000, #0000, #0000, #000);
    }
  }
}
.room-card-slider-wrapper .slick-slide {
  padding: 5px;
}
.room-card {
  overflow: hidden;
  border-radius: 10px;
  // border: 2px solid transparent;
  // box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  width: 310px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
}
#darkMode .room-card {
  box-shadow: none;
}
.room-card.selected {
  border: 1px solid var(--green);
}
.room-card-header {
  height: 130px;
  overflow: hidden;
}
.room-card-header img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease 0s;
}
.room-card:hover .room-card-header img {
  transform: scale(1.05);
}
.room-card-body {
  background: #fff;
  padding: 15px 12px 0;
  height: 234px;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    width: 99%;
    height: 50px;
    background-image: linear-gradient(360deg, #ffffff, transparent);
    position: absolute;
    bottom: 0;
    left: 1px;
  }
}
.room-card-body ul {
  margin: 8px 0 0;
}
.room-card-body ul.list li {
  list-style: disc;
  display: list-item;
  margin: 0 0 0 20px;
  color: var(--gray);
  font-size: 12px;
}
.room-card-body .tabs_tag li {
  border: 1px solid var(--gray);
  border-radius: 6px;
  color: var(--gray);
  font-size: 12px;
  margin: 0 7px 3px 0;
  max-width: 100%;
  overflow: hidden;
  padding: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.room-card-footer {
  padding: 15px 20px;
  text-align: right;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.room-card-footer .addon {
  margin: 0 0 10px;
  font-weight: 600;
  color: var(--blue);
  font-size: 26px;
  letter-spacing: -1px;
  span {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    font-family: var(--noto);
  }
}
.room-card .room-card-footer a {
  font-size: 13px;
  color: #fff;
  background-color: var(--green);
  padding: 12px 15px;
  border-radius: 6px;
  max-width: 100px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-family: var(--noto);
  box-shadow: 0px 10px 20px #05ce7859;
}
.room-card-slider-wrapper .slick-track {
  margin: 0;
}
.room-card-slider-wrapper .slick-arrow {
  bottom: 50%;
}
.room-card-slider-wrapper .slick-slider {
  display: flex;
  justify-content: center;
}
.room-card-slider-wrapper .slick-slide {
  padding: 34px 8px;
  opacity: 0.8;
  transition: transform 500ms ease-in-out;
}
.room-card-slider-wrapper .slick-slide.slick-active.slick-current {
  transform: translateY(-32px);
  margin-top: 0 !important;
  opacity: 1;
}
// .room-card-slider-wrapper .custom-pagination {
//   position: absolute;
//   // left: 50%;
//   width: 60px;
//   bottom: -90px;
//   color: var(--default-color);
//   // transform: translateX(-6%);
// }
#darkMode .custom-pagination {
  color: var(--white);
}
.room-card-slider-wrapper .slick-arrow.arrow-right {
  transform: none;
  right: 0;
}
.room-card-slider-wrapper .slick-arrow.arrow-left {
  transform: none;
  left: 0;
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .room-card-slider-wrapper {
    &::before {
      width: 10%;
    }
    &::after {
      content: " ";
      width: 10%;
    }
  }
  .room-information h5 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .room-information {
    margin-top: 0;
  }
  .room-card-body {
    padding: 15px 15px 0;
  }
  .room-card-body h6 {
    font-size: 18px;
  }
  .room-card-body li {
    padding: 5px 10px;
    font-size: 8px;
  }
  .room-card-footer {
    padding: 10px 15px;
  }
  .room-card-footer .addon {
    margin: 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  // .room-card-header {
  //   height: 90px;
  // }
  .room-card-body h6 {
    font-size: 14px;
  }
  .room-information h5 {
    margin-bottom: 15px;
  }
  .room-card-body {
    padding: 15px 10px 0;
  }
  .room-card-body li {
    padding: 2px 5px;
    font-size: 8px;
    margin: 0 5px 5px 0;
  }
  .room-card-body ul {
    margin: 10px 0 0;
  }
  .room-card-footer {
    padding: 0 10px 15px;
  }
  .showmore-rooms {
    padding: 0 !important;
    // margin-left: 20px !important;
    flex-direction: column;
    img {
      height: 25px !important;
      widows: 33;
    }
    p {
      text-align: center;
      margin-left: 0 !important;
    }
  }
  .room-card-slider-wrapper .slick-slide {
    padding: 34px 6px;
  }
  .room-card-slider-wrapper {
    padding: 0;
  }
  #rooms-section {
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .room-information h5 {
    font-size: 24px;
    span {
      font-size: 12px;
    }
  }
  .room-card-body {
    height: 195px;
  }
}

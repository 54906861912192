.deal-card {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    padding: 15px;
    min-height: 390px;
    width: 100%;
}
.deal-card-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.deal-card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--smooth);
}
.deal-card .text {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
.deal-card .text .price {
    color: var(--blue);
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1.15px;
}
.deal-card .text .price .symbol {
    font-size: 24px;
    margin-right: 5px;
    top: -6px;
}
.deal-card .text .price .static {
    font-size: 12px;
    margin-right: 5px;
    color: var(--gray);
    margin-left: 5px;
    font-weight: 400;
}
.deal-card .card-badge {
    left: -50px;
    padding: 5px;
    top: 25px;
    max-width: 200px;

}
.deal-card .card-badge span {
    color: var(--default-color);
    font-family: var(--noto);
    letter-spacing: -0.5px;
    font-weight: 700;
}

#explore .title {
  text-align: center;
}
#explore .title .subtitle {
  background-color: var(--orange);
  display: inline-block;
  padding: 10px 15px;
  text-transform: uppercase;
  border-radius: 6px;
  margin-bottom: 5px;
  color: var(--white);
}
#explore .title .subtitle img {
  margin-right: 15px;
}
#explore .title h2,
#explore .title p {
  color: var(--black);
  margin-top: 0;
}

#darkMode #explore .title h2 {
  color: var(--white);
}

#darkMode #explore .title p {
  color: var(--gray);
}

@media only screen and (max-width: 767px) {
  #explore .container {
    max-width: 100%;
    // padding: 0;
  }
  #explore .title {
    margin-bottom: 30px;
  }
  #explore .title .subtitle {
    font-size: 12px;
  }
  #explore .title .subtitle img {
    margin-right: 7px;
    max-width: 30px;
  }
  #explore .col-12 {
    padding: 0;
  }
  .Globe-card-slider-wrapper {
    margin-bottom: 40px;
  }
}

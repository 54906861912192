.Detail-card {
  background: #fff;
  padding: 30px 25px;
  min-height: 100%;
  border-radius: 15px;
  box-shadow: 0 0 4px #0000001a;
}

.Detail-card .detail-price-holder {
  position: relative;
  padding-bottom: 25px;
  border-bottom: 1px solid #bdc6d759;
}

.Detail-card .detail-price-holder .cat-icon {
  position: absolute;
  left: -75px;
  top: -40px;
}

.Detail-card .detail-price-holder .price {
  font-size: 32px;
  color: var(--blue);
  text-align: right;
}

.Detail-card .detail-price-holder .price p {
  text-align: right;
  font-size: 14px;
  color: var(--gray);
  margin: 5px 0 0 0;
}

.Detail-card .detail-price-holder .price span {
  font-size: 52px;
  font-weight: 600;
  margin-left: 3px;
  display: inline-block;
  color: var(--blue);
  width: 40px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background-color: var(--veryLight);
  vertical-align: middle;
  border-radius: 6px;
}

.Detail-card .reservation-details {
  padding: 25px 0 0;
  border-bottom: 1px solid #bdc6d759;
}

.Detail-card .reservation-details ul {
  display: flex;
  flex-wrap: wrap;
}

.Detail-card .reservation-details ul li {
  flex: 0 0 50%;
  width: 50%;
  font-size: 13px;
  font-weight: 700;
  margin: 0 0 15px;
  font-family: var(--noto);
}

.Detail-card .planedownleft {
  transform: rotate(320deg) scaleX(-1);
}

.Detail-card .planeupleft {
  transform: rotate(360deg) scaleX(-1);
}

.Detail-card .planedownright {
  transform: rotate(45deg);
}

.Detail-card .reservation-details ul li p {
  margin: 0;
}

.Detail-card .reservation-details ul li span {
  font-weight: 400;
  display: block;
  color: var(--gray);
  font-size: 14px;
  font-family: var(--poppings);
}

.Detail-card .flight-details {
  padding: 25px 0;
  border-bottom: 1px solid #bdc6d759;
}

.Detail-card .flight-details.no-border {
  border-bottom: none;
}

.Detail-card .flight-details .top-caption {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.Detail-card .flight-details .top-caption p {
  margin: 0;
}

.Detail-card .flight-details .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Detail-card .flight-details .text-wrapper {
  display: flex;
  margin-bottom: 15px;
}

.Detail-card .flight-details .text-wrapper:last-child {
  margin: 0;
}

.Detail-card .flight-details .text-wrapper .icon {
  margin-right: 5px;
}

.Detail-card .flight-details .text-wrapper .text p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.Detail-card .flight-details .text-wrapper .text span {
  color: var(--gray);
  display: block;
  font-size: 10px;
}

.Detail-card .flight-details .left {
  position: relative;
}

.Detail-card .flight-details .left:after {
  position: absolute;
  content: "";
  height: 50px;
  width: 1px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, transparent 50%, var(--gray) 50%);
  background-size: 12px 100%;
}

.Detail-card .flight-details .right .text {
  text-align: right;
}

.Detail-card .card-action .book-btn {
  background-color: var(--green);
  color: var(--white);
  width: 100%;
  text-align: center;
  padding: 12px 15px;
  border-radius: 6px;
  font-family: var(--noto);
  // box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 15px 30px #05ce7874;
}

.Detail-card .card-action p {
  text-align: center;
  margin: 10px 0 0;
  font-size: 12px;
}

.Detail-card .card-action p img {
  margin-right: 10px;
}

.floater-total-taxes {
  margin-left: 10px;
  .total {
    margin: 0 !important;
  }
  .text-white {
    margin: 0 !important;
  }
}
.steps-box {
  color: var(--white);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  margin: auto;
  height: 98px;
  filter: drop-shadow(0px 0px 10px #00000078);

  // &::before {
  //   content: "";
  //   display: block;
  //   width: 40px;
  //   height: 45px;
  //   position: absolute;
  //   left: -76px;
  //   bottom: -43px;
  //   border-radius: 0% 0% 0% 100%;
  //   outline: 0px solid;
  //   transform: rotate(264deg);
  //   border-left: 82px solid var(--default-color);
  //   box-sizing: content-box;
  //   z-index: -1;
  // }

  // &::after {
  //   content: "";
  //   display: block;
  //   width: 40px;
  //   height: 45px;
  //   position: absolute;
  //   right: -41px;
  //   bottom: -6px;
  //   border-radius: 0% 0% 0% 100%;
  //   outline: 0px solid;
  //   transform: rotate(355deg);
  //   border-left: 82px solid var(--default-color);
  //   box-sizing: content-box;
  //   z-index: -1;
  // }

  .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;
  }

  .circle {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid var(--orange);
    background-color: var(--default-color);
  }

  .step-icon {
    width: 25px;
    max-width: 100%;
    height: 25px;
  }

  .step-label {
    margin-top: 8px;
    font-size: 14px !important;
    color: var(--white);
  }

  .active.circle {
    background-color: var(--orange);
    img {
      filter: brightness(0) invert(1);
    }
  }

  .completed.circle {
    border-color: var(--orange);
  }

  .circle svg {
    fill: var(--orange);
  }

  .RSPBstep {
    margin-top: 30px;
  }

  .RSPBprogression {
    background: var(--orange) !important;
  }

  .RSPBprogressBar .RSPBstep {
    &:first-child {
      left: 4% !important;
    }
    &:nth-last-child(2) {
      left: 95% !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .Detail-card .detail-price-holder .price {
    font-size: 24px;
  }
  .Detail-card .detail-price-holder .price span {
    font-size: 42px;
    width: 35px;
    height: 45px;
    line-height: 45px;
  }

  .Detail-card .reservation-details,
  .Detail-card .flight-details {
    padding: 10px 0;
  }
  .Detail-card .flight-details .text-wrapper,
  .Detail-card .reservation-details ul li {
    margin: 0 0 10px;
  }
  .Detail-card .card-action p {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .detail-card-wrapper {
    padding: 50px 0;
  }
  .steps-box .circle {
    background-color: var(--white);
  }
  #darkMode .steps-box .circle {
    background-color: var(--default-color);
    &.active {
      background-color: var(--orange);
    }
  }
  .Detail-card {
    padding: 25px 25px 15px;
  }
  .Detail-card .detail-price-holder {
    display: none;
  }
  .Detail-card .reservation-details {
    padding: 0;
  }
  .Detail-card .flight-details .text-wrapper,
  .Detail-card .reservation-details ul li {
    margin: 0 0 20px;
  }
  .Detail-card .reservation-details ul li p {
    font-size: 13px;
  }
  .Detail-card .reservation-details ul li span {
    font-size: 16px;
  }
  .Detail-card .flight-details {
    padding: 25px 0;
  }
  .Detail-card .card-action {
    display: none;
  }
}

//
//.step-progress-bar {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//}
//
//.step {
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//}
//
//.circle {
//  width: 30px;
//  height: 30px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  border-radius: 50%;
//  border: 2px solid #007bff;
//}
//
//.connector {
//  flex: 1;
//  height: 2px;
//  background-color: #007bff;
//}
//
//.step-label {
//  margin-top: 8px;
//  font-size: 14px;
//  color: #555;
//}
//
//.active .circle {
//  background-color: #007bff;
//}
//
//.completed .circle {
//  border-color: #007bff;
//}
//
//.completed .circle svg {
//  fill: #fff;
//}


.tabs-inner-content .for-small{
    display: none;
}
.tab-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tab-title a  {
    background-color: #1A384F;
    color: var(--white);
    padding: 20px 30px;
    border-radius: 50px;
    font-size: 16px;
}
.tab-title a svg,
.tab-title a img {
    margin-right: 5px;
}
.tabs-inner-content {
    min-height: 420px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 50px 25px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    margin-top: 15px;
}
.tabs-inner-content .packages-card-list {
    margin-top: 0;
    margin-bottom: 0;
}
.tabs-inner-content.bookings-tab .packages-card-list {
    margin-top: 20px;
}
.favourites-tab .list-card-image-holder {
    max-width: 230px;
    height: 244px;
    flex: 0 0 230px;
}
.favourites-tab .list-card-content-holder {
    --mask: radial-gradient(20px at calc(100% - 180px) ,#0000 97%,#000) 0 -122px;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}
.tabs-inner-content .destinaotion-action{
    background-color: var(--green);
    color: #fff;
    padding: 15px 25px;
    font-size: 16px;
    font-family: var(--noto);
    border-radius: 6px;
    margin-top: 15px;
    // box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 15px 30px #05ce7874;
}

.account-form-holder form .form-group {
    margin-bottom: 50px;
}
.account-form-holder form .label-style {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--green);
    font-family: var(--noto);
}
.account-form-holder form .input-style {
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid var(--gray);
    width: 100%;
    font-size: 14px;
    font-family: var(--noto);
    outline: none !important;
    color: var(--white);
    padding: 5px 0 10px;
}
.account-form-holder form h6 {
    margin-bottom: 70px;
}
.account-form-holder form .form-action{
    font-size: 13px;
    font-family: var(--noto);
    font-weight: 700;
    background-color: var(--green);
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    max-width: 210px;
    width: 100%;
    text-align: center;
    // box-shadow: 0 0 20px rgba(255,255,255,0.2);
    box-shadow: 0px 15px 30px #05ce7874;
}

.account-form-holder form button.update{
    font-size: 13px;
    font-family: var(--noto);
    font-weight: 700;
    background-color: var(--green);
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    max-width: 210px;
    width: 100%;
    text-align: center;
    // box-shadow: 0 0 20px rgba(255,255,255,0.2);
    box-shadow: 0px 15px 30px #05ce7874;
    outline: none !important;
    border: none;
}
.account-form-holder form button.clear {
    font-size: 13px;
    font-family: var(--noto);
    font-weight: 700;
    background-color: transparent;
    color: var(--gray);
    padding: 15px;
    border-radius: 6px;
    max-width: 130px;
    width: 100%;
    text-align: center;
    box-shadow: none;
    outline: none !important;
    border: 2px solid var(--gray);
    margin-left: 15px;
}
.account-form-holder form .form-group p{
    color: var(--white);
    font-size: 12px;
    margin: 15px 0;
    font-family: var(--noto);
}
.account-form-holder form .form-group .caption p {
    color: var(--default-color);
    font-size: 14px;
    margin: 0;
    font-family: var(--poppings);
}
.account-form-holder .traveler li .qty .qty-val{
    color: var(--default-color);
}
.account-form-holder form .add-more-departures {
    margin: 10px 0;
    color: #fff;
    font-size: 12px;
    font-family: var(--noto);
}
.account-form-holder .form-group .dropdown .dropdown-toggle,
#darkMode .account-form-holder .form-group .dropdown .dropdown-toggle {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none;
    border-bottom: 1px solid var(--gray) !important;
    width: 100%;
    font-size: 14px;
    font-family: var(--noto);
    outline: none !important;
    color: var(--white);
    padding: 5px 0 10px;
    border-radius: 0;
}
select.select-lang-curr option {
    color: #000;
    font-size: 16px;
}
#darkMode select.select-lang-curr option {
    color: #fff;
    background-color: var(--default-color);
    font-size: 16px;
}

.traveler .dropdown-menu {
    max-height: 620px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px; 
    }
    &::-webkit-scrollbar-thumb {
        background: var(--green); 
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--green); 
    }
}
.favourites-tab {
    .swipeable-list-item__content--return {
        transform: translateX(-16%) !important;
        display: block !important;
    }
    .swipeable-list-item__trailing-actions--return {
        width: 14% !important;
        border-top-left-radius: 13px;
        border-bottom-left-radius: 13px;
    }
    .swipeable-list-item__content {
        display: block !important;
    }
    .delete-card {
        background-color: #D93636;
        color: #fff;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .swipeable-list {
        overflow-y: visible;
    }
    .swipeable-list-item {
        margin-bottom: 15px;
        overflow: visible;
    }
    &.tabs-inner-content{
        .accordion {
            margin-bottom: 16px;
        }
    }
}
.account-delete {
    color: var(--white);
    margin-bottom: 30px;
    .delete-btn {
        box-shadow: 0px 15px 30px #12121274;
        font-size: 13px;
        font-family: var(--noto);
        font-weight: 700;
        background-color: var(--default-color);
        color: #fff;
        padding: 15px;
        border-radius: 6px;
        max-width: 210px;
        width: 100%;
        text-align: center;
        outline: none !important;
        border: none;
    }
}

@media only screen and (max-width:1399px ){
    .tabs-inner-content .packages-card-list .list-card-image-holder {
        max-width: 160px;
        flex: 0 0 160px;
    }
}
@media only screen and (max-width: 1199px){
    .tab-title a {
        color: var(--white);
        padding: 12px 25px;
    }
    .tabs-inner-content .packages-card-list {
        margin-top: 15px;
    }
    .favourites-tab.tabs-inner-content .packages-card-list {
        margin-top: 0;
    }
    .tabs-inner-content .packages-card-list .list-card-image-holder {
        max-width: 140px;
        flex: 0 0 140px;
    }
    .tabs-inner-content .packages-card-list .fav-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        right: auto;
        top: auto;
        border-radius: 100%;
        bottom: 15px;
        left: 15px;
    }
    .tabs-inner-content .packages-card-list .fav-icon i {
        font-size: 16px;
    }
    .tabs-inner-content .packages-card-list .card-badge {
        left: -40px;
        padding: 5px;
        top: 12px;
        max-width: 160px;
    }
    .tabs-inner-content .packages-card-list .card-badge span {
        font-size: 8px;
        margin-top: 3px;
    }
    .tabs-inner-content .packages-card-list .list-card-content-Body .features-tags li {
        font-size: 11px;
        padding: 3px 7px;
        margin-right: 3px;
    }
    .tabs-inner-content .packages-card-list  .list-card-content-Body {
        flex-basis: calc(100% - 160px);
    }
    // .tabs-inner-content .packages-card-list .list-card-pricing:before {
    //     left: 18px;
    // }
    .favourites-tab .list-card-content-holder {
        --mask: radial-gradient(20px at calc(100% - 160px) ,#0000 97%,#000) 0 -116px;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }
    .tabs-inner-content .packages-card-list .list-card-pricing {
        padding: 25px 15px;
        max-width: 160px;
        flex: 0 0 160px;
    }
    .tabs-inner-content .packages-card-list .list-card-pricing .logo-holder p {
        font-size: 9px;
        font-weight: 700;
        margin-top: 6px;
    }
    .tabs-inner-content {
        padding: 30px 15px;
        margin-top: 10px;
    }
}

@media only screen and (max-width:767px) {
    .tabs-inner-content {
        padding: 20px;
    }
    .tabs-inner-content .for-dekstop{
        display: none;
    }
    .tabs-inner-content .for-small{
        display: block;
    }
    .tabs-inner-content .small-package-card {
        margin-bottom: 0;
        margin-top: 15px;
    }
    .favourites-tab.tabs-inner-content .small-package-card {
        margin-top: 0;
    }
    .account-form-holder form h6 {
        margin-bottom: 50px;
    }
    .account-form-holder form .form-group {
        margin-bottom: 30px;
    }
    .account-form-holder form button.update {
        max-width: 100%;
        margin-bottom: 15px;
    }
    .account-form-holder form button.clear {
        max-width: 100%;
        margin-left: 0;
    }
    .favourites-tab .swipeable-list-item__content--return {
        transform: translateX(-23%) !important;
    }
    .favourites-tab .swipeable-list-item__trailing-actions--return {
        width: 20% !important;
    }
}

@media only screen and (max-width:576px){
    .tabs-inner-content .small-package-card {
        border-radius: 20px;
        flex-wrap: wrap;
    }
    .tabs-inner-content .small-package-card .small-card-image-holder {
        max-width: 100%;
        flex: 0 0 100%;
        height: 150px;
    }
    .tabs-inner-content .small-card-content-holder {
        flex-basis: 100%;
        width: 100%;
        padding: 15px;
    }
    .tabs-inner-content .small-package-card .fav-icon {
        right: 15px;
        top: 15px;
        bottom: auto;
        left: auto;
    }
    .tabs-inner-content .small-package-card .card-badge {
        left: -50px;
        padding: 5px;
        top: 10px;
        width: 170px;
    }
    .favourites-tab .swipeable-list-item__trailing-actions--return {
        width: 35% !important;
    }
    .favourites-tab .swipeable-list-item__content--return {
        transform: translateX(-40%) !important;
    }
    .favourites-tab .swipe-action {
        align-items: center;
        justify-content: center;
    }
    .favourites-tab .delete-card {
        background-color: #D93636;
        color: #fff;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 10px;
        border-radius: 13px;
    }
}

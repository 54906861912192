.room-pricing-action a {
    background-color: var(--green);
    color: var(--white) !important;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-radius: 6px;
    font-size: 13px;
    font-family: var(--noto);
}

.room-pricing-action p {
    margin: 16px 0 10px;
    text-align: center;
    font-family: var(--noto);
    font-size: 14px;
    span {
        font-size: 18px;
        font-weight: 700;
        color: var(--green);
    }
}

#darkMode {
    .room-pricing-action p {
        color: var(--white);
    }
}

#RomContent {
    max-width: 990px;
    margin: 0 auto 130px;
}

#RomContent .content-holder .subtitle img{
    margin-right: 10px;
}
#darkMode #RomContent .content-holder h2{
    color: var(--white);
}
#RomContent .content-holder p{
    color: var(--default-color);
    font-size: 16px;
    font-family: var(--noto);
    margin: 0 0 25px;
}
#RomContent .content-holder p:last-child{
    margin: 0;
}
#darkMode #RomContent .content-holder p{
    color: var(--gray);
}
#RomContent .content-holder .more-info {
    display: flex;
    align-items: center;
    margin: 10px 0px 15px;
}
#RomContent .content-holder .more-info .stars {
    margin-top: 7px;
  }
  #RomContent .content-holder .more-info .star svg {
    margin-right: 5px;
    font-size: 13px;
  }
  #RomContent .content-holder .more-info .star svg.filled {
    color: #ffc500;
  }
  #RomContent .content-holder .more-info .star svg.blank {
    color: #777e90;
  }
  #RomContent .content-holder .more-info span {
    color:var(--default-color);
    font-size: 13px;
    font-family: var(--poppings);
  }
#darkMode #RomContent .content-holder .more-info span{
    color: var(--gray);
}
.feat-detail-wrapper {
    margin-top: 50px;
}
.feat-detail-wrapper h6{
    font-size: 22px;
    margin-bottom: 25px;
}
.feat-detail-holder {
    margin: 0 0 50px;
}
.feat-detail-holder h6 {
    font-size: 22px;
    font-family: var(--noto);
    margin: 0 0 15px;
}
// .feat-detail-holder ul li {
//     width: 100%;
//     margin: 0 0 15px;
//     font-size: 14px;
//     font-family: var(--noto);
// }
.feat-detail-holder ul li:last-child{
    margin-bottom: 0;
}
#darkMode .feat-detail-wrapper h6{
    color: var(--green);
}
#darkMode .feat-detail-holder ul li {
    color: var(--gray);    
}


@media only screen and (max-width: 991px){
    #RomContent .content-holder .subtitle {
    padding: 5px 15px;
    }
    #RomContent .content-holder h2 {
    font-size: 28px;
    }
    #RomContent .content-holder p {
        font-size: 14px;
    }
    #RomContent .content-holder .features-deatils li {
        font-size: 14px;
        padding-bottom: 10px;
    }
    #RomContent .content-holder .features-deatils li:nth-child(odd)::before {
        top: 10px;
        left: -20px;
    }
    
    
}

@media only screen and (max-width: 767px){
    #RomContent .content-holder p {
        margin: 0 0 15px;
    }
    #RomContent .content-holder .more-info {
        margin: 5px 0px 15px;
    }
    .features-deatils {
        margin-top: 30px;
    }
}
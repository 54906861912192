.tooltip-wrapper{
  .rc-tooltip-arrow{
    border-top-color: #FF8700;
  }
  .rc-tooltip-inner{
    font-weight: 700;
    padding: 2px 10px;
    background-color: #FF8700;
    border-radius: 30px;
    min-height: auto;
  }
}
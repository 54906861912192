// Hotal Grid  Card
.packages-card-grid {
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0 0 15px #0000001a);
  margin-bottom: 30px;
  cursor: pointer;
}
.packages-card-header {
  position: relative;
  overflow: hidden;
  height: 160px;
  transition: var(--smooth);
}
.packages-card-grid:hover .covered-image {
  transform: scale(1.1);
}
.packages-card-Body {
  padding: 20px 20px 4px 20px;
  position: relative;
  z-index: 2;
  background-color: var(--white);
}
// .packages-card-Body:after {
//   content: "";
//   position: absolute;
//   width: 90%;
//   height: 2px;
//   background-image: linear-gradient(to right, transparent 50%, var(--gray) 50%);
//   background-size: 12px 100%;
//   bottom: -10px;
//   top: auto;
//   left: auto;
//   left: 50%;
//   transform: translateX(-50%);
// }
.packages-card-Body .reviews {
  margin: 7px 0px;
}
.packages-card-Body .reviews .star svg {
  margin-right: 5px;
  //   font-size: 12px;
}
.packages-card-Body .reviews .star svg.filled {
  color: #ffc500;
}
.packages-card-Body .reviews .star svg.blank {
  color: #777e90;
}
.packages-card-Body .reviews span {
  color: #777e90;
  font-size: 12px;
  font-family: var(--poppings);
}
.location {
  color: #777e90;
  font-family: var(--poppings);
  margin: 7px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.location svg {
  margin-right: 10px;
}
.info-tags {
  display: flex;
  // justify-content: space-around;
  margin: 10px 0;
}
.info-tags li {
  padding: 5px 5px;
  margin-right: 5px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  color: var(--darkgray);
}
.packages-card-Footer {
  padding: 4px 20px 20px 20px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: var(--white);
}

.seprator-mask.medium {
  height: 40px;
  --mask: radial-gradient(20px at 100px, #0000 95%, #000) -100px 0;
  mask: var(--mask);
  &::before {
    height: 2px;
    background-image: linear-gradient(to right, transparent 50%, var(--gray) 0%);
  }
}
.logo-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media (min-width: 577px) {
    height: 60px;
  }
}
.daily-offer {
  background: red;
  width: fit-content;
  padding: 7px 16px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  left: 10%;
  bottom: -30px;
  transform: rotate(-5deg);
}
.daily-offer-list {
  background: red;
  width: fit-content;
  padding: 7px 16px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  bottom: 15px;
}
.daily-offer-list-small {
  background: red;
  width: fit-content;
  padding: 5px 12px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  left: 4%;
  top: -26px;
  transform: rotate(-4deg);
  @media (max-width: 767px) {
    font-size: 9px;
    padding: 2px 8px;
    border-radius: 4px;
    left: 8%;
    top: -19px;
  }
}
.logo-holder p {
  font-size: 11px;
  font-weight: 700;
  margin: 0;
}
.logo-holder p img {
  margin-right: 5px;
  display: inline-block;
}
.price-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
}
.price-holder .price {
  font-size: 18px;
  font-weight: 700;
  color: var(--blue);
  vertical-align: middle;
  display: inline-block;
}
.price-holder .price span {
  font-size: 28px;
  font-weight: 600;
  margin-left: 2px;
  display: inline-block;
  color: var(--blue);
  width: 20px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: var(--veryLight);
  vertical-align: middle;
  border-radius: 4px;
}
.price-holder .action {
  flex: 0 0 50%;
  width: 50%;
  padding-left: 15px;
  @media (max-width: 390px) {
    padding-left: 6px;
  }
}
.price-holder .action .action-btn {
  background: var(--green);
  color: #fff;
  padding: 10px 10px;
  display: block;
  border-radius: 4px;
  text-align: center;
  // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 15px 30px #05ce7874;
  border: none;
  width: 100%;
}
.action .action-btn:hover {
  background: green;
}
.price-caption {
  margin: 0;
  color: #596c7f;
  font-size: 10px;
}

// List View Card
.default-list-card {
  .list-card-image-holder {
    width: 190px;
    height: 240px;
    flex: 0 0 190px;
  }
  h4 {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .list-card-content-Body {
    padding: 5px 14px;
    .info-tags {
      margin: 5px 0 0;
      flex-wrap: wrap;
      gap: 5px;
      li {
        margin-right: 0;
      }
    }
    .location {
      margin: 0;
    }
  }
  .list-card-pricing {
    padding: 16px 10px;
    &::before {
      width: 1px;
    }
  }
  .list-card-content-holder {
    --mask: radial-gradient(20px at calc(100% - 180px), #0000 97%, #000) 0 -121px;
    mask: var(--mask);
  }
}

.packages-card-list {
  display: flex;
  overflow: hidden;
  min-height: 230px;
  border-radius: 13px;
  margin-bottom: 15px;
  filter: drop-shadow(0 0 15px #0000001a);
  cursor: pointer;
}
.list-card-image-holder {
  position: relative;
  overflow: hidden;
  transition: var(--smooth);
  width: 280px;
  height: 266px;
  flex: 0 0 280px;
}
.packages-card-list:hover .list-card-image-holder img {
  transform: scale(1.1);
}

.list-card-content-holder {
  flex: calc(100% - 280px);
  display: flex;
  width: calc(100% - 280px);
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  background-color: #fff;
  --mask: radial-gradient(20px at calc(100% - 180px), #0000 97%, #000) 0 -134px;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}
.list-card-content-Body {
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 180px);
  position: relative;
  z-index: 2;
  width: calc(100% - 180px);
}
.list-card-content-Body .reviews {
  margin: 7px 0px;
}
.list-card-content-Body .reviews .star svg {
  margin-right: 5px;
}
.list-card-content-Body .reviews .star svg.filled {
  color: #ffc500;
}
.list-card-content-Body .reviews .star svg.blank {
  color: #777e90;
}
.list-card-content-Body .reviews span {
  color: #777e90;
  font-size: 12px;
  font-family: var(--poppings);
}
.list-card-content-Body .info-tags {
  justify-content: flex-start;
  margin: 25px 0 10px;
}
.list-card-content-Body .features-tags {
  display: flex;
}
.list-card-content-Body .features-tags li {
  border: 1px solid var(--gray);
  color: #777e90;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 15px;
  margin-right: 5px;
}

// #darkMode .list-card-content-Body .features-tags li {
//   background-color: #091e30;
//   color: #fff;
// }

.list-card-pricing {
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  flex: 0 0 180px;
  position: relative;
  z-index: 2;
}
.list-card-pricing::before {
  content: "";
  height: 90%;
  width: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    transparent 50%,
    var(--gray) 50%
  );
  background-size: 100% 12px;
  transform: translateY(-50%);
}
.list-card-pricing .logo-holder,
.list-card-pricing .price-holder {
  flex-direction: column;
  align-items: flex-end;
}
.list-card-pricing .logo-holder p {
  font-size: 12px;
  font-weight: 700;
  margin-top: 6px;
}
.list-card-pricing .logo-holder {
  margin-bottom: 15px;
}
.list-card-pricing .price-holder .action {
  flex: 0 0 100%;
  width: 100%;
  padding-left: 0;
  margin-top: 15px;
}
.list-card-pricing .price-caption {
  margin: 0;
  color: #596c7f;
  font-size: 12px;
  margin-top: 5px;
}

// Small List Card
.row.small-list-card {
  display: none;
}
.small-package-card {
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 13px;
  filter: drop-shadow(0 0 15px #0000001a);
}
.small-card-image-holder {
  position: relative;
  overflow: hidden;
  transition: var(--smooth);
  max-width: 220px;
  flex: 0 0 220px;
  @media (max-width: 560px) {
    flex:auto;
  }
}
.small-card-content-holder {
  position: relative;
  background: #fff;
  flex: 0 0 calc(100% - 220px);
  padding: 25px 15px;
  width: calc(100% - 220px);
}
.small-card-content-holder .reviews {
  margin: 7px 0px;
}
.small-card-content-holder .reviews .star svg {
  margin-right: 5px;
}
.small-card-content-holder .reviews .star svg.filled {
  color: #ffc500;
}
.small-card-content-holder .reviews .star svg.blank {
  color: #777e90;
}
.small-card-content-holder .reviews span {
  color: #777e90;
  font-size: 12px;
  font-family: var(--poppings);
}
.small-card-content-holder .info-tags {
  justify-content: flex-start;
  margin: 15px 0;
}
.small-card-content-holder .seperator {
  background-image: linear-gradient(to right, transparent 50%, var(--gray) 50%);
  background-size: 12px 100%;
  width: 100%;
  height: 2px;
  background-color: transparent;
  opacity: 1;
  margin: 0 0 15px;
  border: none;
}

// @media only screen and (max-width: 1400px) {
//   .list-card-pricing:before {
//     left: 15px;
//   }
// }

@media only screen and (max-width: 1199px) {
  .row.default-list-card {
    display: none;
  }
  .row.small-list-card {
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .small-package-card .fav-icon {
    right: auto;
    top: auto;
    bottom: 15px;
    left: 15px;
  }
  .favourites-tab .small-card-content-holder .price-holder .action .action-btn {
    font-size: 12px;
    max-width: 110px;
  }
  .small-package-card .card-badge {
    left: -40px;
    padding: 5px;
    top: 10px;
  }
  .small-package-card .small-card-image-holder {
    max-width: 150px;
    flex: 0 0 150px;
  }
  .small-card-content-holder {
    // flex-basis: calc(100% - 150px);
    width: calc(100% - 150px);
  }
  .small-card-content-holder .heading {
    font-size: 18px;
  }
  .small-card-content-holder .reviews {
    margin-top: 5px;
  }
  .small-card-content-holder .reviews .star i {
    margin-right: 3px;
    font-size: 12px;
  }
  .small-package-card .location {
    margin: 5px 0 0;
    font-size: 12px;
  }
  .small-card-content-holder .info-tags {
    justify-content: flex-start;
    margin: 10px 0;
  }
  .small-card-content-holder .info-tags li {
    margin-right: 7px;
    font-size: 10px;
  }
  .small-card-content-holder .seperator {
    margin: 0 0 10px;
  }
  .small-card-content-holder .price-holder .price span {
    font-size: 17px;
    margin-left: 2px;
    width: 16px;
    height: 30px;
    line-height: 30px;
  }
  .small-card-content-holder .price-holder .action .action-btn {
    padding: 7px;
    max-width: 90px;
    margin-left: auto;
  }
  .small-card-content-holder .logo-holder p {
    font-size: 10px;
  }
  .small-list-card .card-badge {
    max-width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .packages-card-Body {
    padding: 15px 16px 5px;
  }
  .packages-card-Footer {
    padding: 5px 16px 20px;
  }
  .packages-card-Body h4 {
    font-size: 18px;
  }
  .packages-card-Body .reviews .star i,
  .location {
    font-size: 11px;
  }
  .info-tags li {
    padding: 5px 3px;
    margin-right: 2px;
    font-size: 10px;
  }
  .logo-holder p {
    font-size: 10px;
  }
  .logo-holder p img {
    margin-right: 2px;
  }
  .logo-holder img {
    max-width: 90px;
    height: 36px;
    object-fit: contain;
  }
  .logo-holder p img {
    max-width: 100%;
    height: 12px;
    display: block;
    margin: auto;
    margin-bottom: 5px;
  }
  .price-holder .price span {
    font-size: 17px;
    margin-left: 2px;
    width: 16px;
    height: 30px;
    line-height: 30px;
  }
  .price-holder .price {
    font-size: 16px;
  }
  .small-list-card .small-card-content-holder .heading {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
  }
  .small-list-card .small-card-content-holder .reviews span {
    font-size: 11px;
  }
  .small-list-card .small-card-content-holder .reviews {
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-list-card .location svg {
    margin-right: 7px;
  }
  .small-list-card .small-card-content-holder .info-tags li {
    margin-right: 5px;
    font-size: 9.5px;
  }
  .small-list-card .small-card-content-holder .logo-holder p {
    font-size: 9px;
  }
  .small-list-card .small-card-content-holder .reviews .star {
    margin-bottom: 3px;
  }
}
@media only screen and (max-width: 480px) {
  .small-package-card .fav-icon {
    right: auto;
    top: auto;
    bottom: 15px;
    left: 15px;
  }
  .small-package-card .card-badge {
    left: -40px;
    padding: 5px;
    top: 15px;
    width: 150px;
  }
  .small-package-card .small-card-image-holder {
    max-width: 110px;
    flex: 0 0 110px;
  }
  .small-card-content-holder {
    flex-basis: calc(100% - 110px);
    width: calc(100% - 110px);
    padding: 15px;
  }
}

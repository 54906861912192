#detailsImage {
    margin: 50px 0;
}
#detailsImage .image-holder {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 14px;
}
#detailsImage .image-holder.large {
    // height: 590px;
    height: 390px;
}
#detailsImage .image-holder.small {
    height: 188px;
}
#detailsImage .image-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#detailsImage .row {
    margin: 0 -7px;
}
#detailsImage .col-lg-6,
#detailsImage .col-lg-3 {
    padding: 0 7px;
}
.view-gallery-action {
    position: absolute;
    bottom: 25px;
    left: 25px;
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &.mobile {
        display: none;
    }
}
.view-gallery-action a {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    color: #fff;
    padding: 10px 15px;
    border-radius: 25px;
    transition: all 0.3s ease 0s;
    font-size: 12px;
    font-family: var(--noto);
}
.view-gallery-action a svg {
    margin-right: 10px;
}
.view-gallery-action a:hover {
    background: rgba(255, 255, 255, 1);
    backdrop-filter: blur(0x);
    color: var(--default-color);
}
@media only screen and (max-width: 991px) {
    #detailsImage .image-holder.large {
        // height: 380px;
        height: 250px;
    }
    #detailsImage .image-holder.small {
        height: 118px;
    }
}
@media only screen and (max-width: 767px) {
    #detailsImage {
        margin: 20px 0;
    }
    #detailsImage .row {
        flex-wrap: nowrap;
        overflow: auto;
    }
    #detailsImage .col-lg-7 {
        padding: 0 7px;
        flex: 0 0 300px;
        max-width: 300px;
    }
    #detailsImage .col-lg-5 {
        display: flex;
        // flex: 1 1 auto;
        // max-width: 100%;
        flex: 0 0 630px;
        max-width: 630px;
        gap: 16px;
    }
    #detailsImage .image-holder {
        flex: 0 0 320px;
        max-width: 300px;
        height: 420px;
    }
    #detailsImage .col-lg-6 .image-holder {
        margin-right: 15px;
    }
    .view-gallery-action {
        bottom: 16px;
        left: 15px;
        &.mobile {
            display: block;
        }
        &.desktop {
            display: none;
        }
    }
    #detailsImage .image-holder.large {
        height: 300px;
    }
    #detailsImage .image-holder.small {
        height: 300px;
    }
}

.image-viewer-container .styles-module_wrapper__1I_qj {
    z-index: 10;
    backdrop-filter: blur(6px);
    background-color: #000000a8;
}

.photo_animation {
    width: 35px;
    margin-right: 5px;
}

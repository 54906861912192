.static-page {
  .body-wrapper {
    margin-top: 3%;
    background: #ffffff80;
    border-radius: 16px;
    padding: 38px;
    backdrop-filter: blur(8px);
    color: var(--default-color);
    min-height: auto;
    h2 {
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      font-family: var(--noto);
      margin: 0 0 25px;
    }
  }
}
#darkMode {
  .static-page {
    .body-wrapper {
      background: #0000004e;
      backdrop-filter: blur(8px);
      color: #ffffffcd;
      h2 {
        color: var(--white);
      }
    }
  }
}

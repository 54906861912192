
.range-bar {
  // display: flex;
  // justify-content: flex-end;
  // align-items: flex-end;
  position: relative;
  min-height: 60px;
}

.range-bar .range-value {
  display: inline-block;
  // color: #fff;
  color: var(--darkgray);
  margin-bottom: -42px;
  font-size: 12px !important;
  font-family: var(--noto);
  min-width: 35px;
}

.range-bar .caption {
  position: absolute;
  left: 0;
  top: 0;
}

.range-bar .caption p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  // color: #fff;
  color: var(--darkgray);
  line-height: 1;
}

#darkMode {
  .range-bar .caption p {
    color: #fff;
    span {
      color: #fff;
    }
  }
} 

.stars-range-wrapper .range-bar .caption p {
  color: var(--darkgray);
}

#darkMode .stars-range-wrapper .range-bar .caption p {
  color: var(--white);
}

.range-bar .caption p span {
  font-size: 12px;
  font-family: var(--noto);
  font-weight: 400;
  // color: #fff;
  color: var(--darkgray);
  display: block;
}

.range-slider-holder {
  width: 100%;
  position: relative;
  padding: 0 12px;
}

.tiles {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 9px !important;
}

.range-slider-holder
.slider {
  position: relative;
  width: 100%;
}

.slider-container {
  position: relative;
  // width: 250px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #FFF;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: var(--green);
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: var(--white);
  font-size: 10px;
  background-color: var(--orange);
  display: inline-block;
  line-height: 20px;
  height: 20px;
  width: 35px;
  text-align: center;
  border-radius: 6px;
  top: -30px;
}

.slider__left-value:after,
.slider__right-value:after {
  position: absolute;
  left: 50%;
  content: "";
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--orange);
  bottom: -4px;
  z-index: 9999;
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: 0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #fff;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #FFF;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #FFF;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

#darkMode .slider__track {
  background-color: var(--gray);
}

//   Titles background color
.default-bg-color {
  background-image: linear-gradient(to right, #05ce78, var(--green), #13af6c);
  width: 6px !important;
  border-radius: 4px !important;
}

.filled-bg-color {
  background-color: var(--white) !important;
  width: 8px !important;
  border-radius: 10px !important;
  // box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.602);
}

@media only screen and (max-width: 991px) and (min-width:768px) {
  .default-bg-color {
    width: 5px !important;
  }
  .filled-bg-color {
    width: 5px !important;
  }
  .rc-slider-track, .rc-slider-tracks {
    height: 5px !important;
  }
  .rc-slider-rail {
    height: 5px !important;
  }
  .rc-slider-handle {
    top: 0 !important;
  }
}

.rc-slider-track, .rc-slider-tracks {
  background-image: linear-gradient(to bottom, #05ce78, var(--green), #13af6c);
  height: 6px;
}

.rc-slider-rail {
  height: 6px;
  // box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.602);
}

#darkMode .filled-bg-color {
  background-color: var(--gray) !important;
}

.range-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  cursor: grab;
  opacity: 0.8;
  touch-action: pan-x;
  // top: 2px;
}

// New Chnages

// .range-container {
//   display: flex;
//   align-items: flex-end;
//   justify-content: space-between;
// }
// .range-slider-holder {
//   flex-grow: 1;
//   padding: 0 15px;
// }
// .range-slider-holder .tiles {
//   display: flex;
//   align-items: flex-end;
//   justify-content: space-evenly;
// }

.blog-card-wrapper {
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 40px rgba(0, 0, 0,0.2);
    align-items: center;
    max-width: 980px;
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
}
.blog-image {
    flex: 0 0 380px;
    width: 380px;
    height: 340px;
}
.blog-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blog-content {
    padding: 30px;
}
.blog-content .heading{
    color: var(--black);
    font-weight: 600;
    font-size: 38px;
}
.blog-content p{
    color: var(--gray);
    font-size: 16px;
    font-family: var(--noto);
}
.blog-content a{
    color: var(--gray);
    font-size: 16px;
    font-family: var(--noto);
    font-weight: 700;
    transition: var(--smooth);
}
.blog-content p a{
    color: var(--black);
    font-weight: 400;
    text-decoration: underline;
}
.blog-content a svg {
    transition: var(--smooth);
    font-size: 16px;
    vertical-align: middle;
    margin-left: 5px;
    font-weight: 700;
}
.blog-content a:hover{
    color: var(--black);
}
#darkMode .blog-content a:hover{
    color: var(--white);
}
.blog-content a:hover i{
    transform: translateX(5px);
}


#darkMode .blog-card-wrapper {
    background-color: #000C24;
}

#darkMode .blog-content .heading {
    color: var(--white);
}

#darkMode .blog-content p a{
    color: var(--white);
}

.blog-card-grid{
    .blog-card-wrapper {
        flex-wrap: wrap;
    }
    .blog-image {
        flex: 0 0 100%;
        width: 100%;
        height:250px;
    }
    .blog-content .heading{
        font-weight: 500;
        font-size: 25px;
        margin-top: 10px;
    }
}


@media only screen and  (max-width: 991px){
    .blog-content .heading {
        font-size: 28px;
    }
    .blog-image {
        flex: 0 0 260px;
        width: 280px;
        height: 350px;
    }
    .blog-content {
        padding: 15px;
    }
    .blog-card-grid{
        .blog-content {
            padding: 30px;
        }
    }
}
@media only screen and  (max-width: 767px){
    .blog-card-wrapper {
        flex-wrap: wrap;
    }
    .blog-image {
        flex: 0 0 100%;
        width: 100%;
        height:200px;
    }
    .blog-content {
        padding: 30px;
    }
    .blog-content .heading {
        font-size: 20px;
    }
    .blog-content p {
        font-size: 14px;
     }
}
.main-wrapper {
  background-image: url(../../assets/images/main-background-light.png),
    url(../../assets/images/main-background-dark.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover, 0;
}

#darkMode .main-wrapper {
  background-size: 0, cover;
}

.public-beta {
  width: 100px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 0;
  filter: drop-shadow(0 0 30px #00000044);
  @media (max-width: 1400px) {
    width: 100px;
    position: absolute;
    right: 30px;
    top: 70px;
    z-index: 0;
    filter: drop-shadow(0 0 30px #00000044);
  }
  @media (max-width: 1200px) {
    width: 85px;
    position: absolute;
    right: 30px;
    top: 115px;
    z-index: 0;
    filter: drop-shadow(0 0 30px #00000044);
  }
  @media (max-width: 991px) {
    width: 80px;
    position: absolute;
    right: 20px;
    top: 70px;
    z-index: 0;
    filter: drop-shadow(0 0 30px #00000044);
  }
  @media (max-width:767px) {
    width: 70px;
    position: absolute;
    right: 12px;
    z-index: 0;
    top: 70px;
    filter: drop-shadow(0 0 20px #000000ab);
  }
}
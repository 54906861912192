#faq {
    padding: 0 0 130px;
    max-width: 930px;
    margin: 0 auto;
}
#faq .title {
    margin-bottom: 50px;
}
#faq .title .subtitle{
    font-size: 22px;
    color: var(--white);
    font-weight: 600;
}
#faq .title h2 {
    color: var(--white);    
}
@media only screen and (max-width: 576px){
    #faq .title h2 {
        font-size: 20px;  
        text-align: center;  
    }
    #faq .title {
        margin-bottom: 30px;
    }
}
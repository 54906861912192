#topBar {
    padding-top: 30px;
    z-index: 9;
    position: relative;
}
#topBar .content-holder {
    display: flex;
    justify-content: space-between;
    // background: rgba(255,255,255,0.2);
    background: #D8D8D81A;
    padding: 15px;
    border-radius: 50px;
    align-items: center;
    backdrop-filter: blur(10px);
}
#topBar .left-action a {
    border: 1px solid var(--gray);
    padding: 7px 25px;
    border-radius: 25px;
    color: var(--white);
    background: #00000082;
}

#topBar .left-action img {
    border: 1px solid var(--gray);
    padding: 7px 15px;
    border-radius: 25px;
    color: var(--white);
    cursor: pointer;
}

#topBar .show-map-link{
    background-color: var(--blue);
    padding: 7px 12px;
    border-radius: 25px;
    color: var(--white);
    display: flex;
    align-items: center;
    flex-direction: row;
}
#topBar .right-action ul li {
    margin: 0 15px;
}
#topBar .right-action ul li a img{
    margin-right: 10px;
    width: 18px;
}
#topBar .right-action ul li a{
    color: var(--white);
    font-family: var(--noto);
}
.share-button{
    .dropdown-toggle {
        background-color: transparent;
        border: 0;
        padding: 0;
        img {
            margin: -4px 10px 0 0;
            @media (max-width:576px) {
                margin: -4px 10px 0 0;
            }
        }
        &::after {
            display: none;
        }
    }
    .dropdown-menu {
        padding: 15px;
        h6 {
            font-size: 16px;
            margin-bottom: 8px;
        }
        .d-flex {
            border: 1px solid var(--gray);
            border-radius: 8px;
            padding: 8px 8px 8px 16px;
            #copyButton {
                border: 0;
                background-color: var(--blue);
                color: var(--white);
                padding: 4px 10px;
                border-radius: 4px;
            }
        }
        input {
            border: 0 !important;
        }
    }  
} 
@media only screen and (max-width: 767px){
    #topBar .left-action a {
        padding: 5px 15px;
    }
    #topBar .right-action ul li {
        margin: 0 10px;
    }
    #topBar .show-map-link {
        padding: 5px 15px;
        @media (max-width:576px) {
            padding: 5px 0 5px 5px;
        }
    }
    .show-map-link span {
        display: none;
    }
    #topBar .right-action ul li a img {
        margin-right: 5px;
    }
}
@media only screen and (max-width: 576px){
    #topBar .right-action ul li {
        margin: 0 5px;
    }
}

.map_animation {
    width: 25px;
    margin-right: 5px;
}

.expedia-section {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 991px) {
        display: none;
    }
    p {
        cursor: pointer;
        color: var(--white);
        margin: 0;
        text-align: center;
    }
}
.custom-checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .custom-checkbox label {
    position: relative;
    cursor: pointer;
    color: var(--default-color);
    font-family: var(--noto);
  }
  .custom-checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 5px solid #182C3D;;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 7px;
    background: #4C6180;
    border-radius: 4px;
}
.custom-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 12px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
}

.custom-checkbox label span{
    color:#FFC500 ;
    margin-right: 7px;
}

#darkMode .custom-checkbox label {
    color: var(--white);
  }
#darkMode  .custom-checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 5px solid #182C3D;
    background-color: #4C6180;
}

#darkMode .custom-checkbox input:checked + label:after {
    border: solid #fff;
    border-width: 0px 1px 1px 0;
    }
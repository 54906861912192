.container-download {
  position: absolute;
  top: 60px;
  width: 100%;
  left: 0;
  right: 0;
  background-color:  var(--white);
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;

  .icon {
    cursor: pointer;
  }

  .brand_logo {
    width: 50px;
    max-height: 50px;
  }
  h6 {
    font-size: 14px;
  }
  p {
    color: var(--black);
    margin: 0;
    font-size: 12px;
  }
  svg {
    font-size: 20px;
  }
  a {
    margin-left: auto;
    padding: 6px 8px;
    border: 1px solid #000;
    border-radius: 6px;
    &:hover {
      color: var(--black);
    }
  }
}
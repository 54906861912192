.slick-slide {
  padding: 40px 15px;
}
.slick-arrow {
  border: 1px solid transparent;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #fff;
  z-index: 3;
  top: auto;
  position: absolute;
  transition: all 0.3s ease 0s;
  bottom: -20px;
  // left: 50% !important;
}
.slick-arrow.arrow-right {
  transform: translateX(60%);
}
.slick-arrow.arrow-left {
  transform: translateX(-60%);
}
.slick-arrow:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--green);
}
@media only screen and (max-width: 991px) {
  .slick-slide {
    padding: 40px 10px;
  }
  .slick-slide.slick-active.slick-center {
    margin-top: -40px;
  }
  .packages-wrapper {
    .package-country {
      .slick-arrow {
        bottom: 38%;
      }
      .slick-slide.slick-active.slick-center {
        margin-top: 0;
        scale: 1.15;
        z-index: 99;
        position: relative;
        transition: 0.3s linear;
        .globe-card {
          .globe-card-header {
            box-shadow: 0 0 10px #ffffff;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .slick-slide {
    padding: 40px 5px;
  }
}

#highlights{
    padding: 70px 0 ;
}
#highlights .title{
    margin-bottom: 50px;
}
#darkMode #highlights .title h2{
    color: var(--white);
}
@media only screen and (max-width: 991px){
    #highlights {
        padding: 50px 0;
    }
}
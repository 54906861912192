#mobileApp {
    margin: 70px 0;
}
#mobileApp .title{
    margin-bottom: 30px;
}
#mobileApp .title p {
    margin: 10px 0 0;
    max-width: 350px;
}
#mobileApp .app-actions {
    margin-top: 30px;
}
#mobileApp .app-actions a {
    display: block;
    margin-bottom: 15px;
}

#darkMode #mobileApp .title h2 {
    color: var(--white);    
}
#darkMode #mobileApp .title p{
    color: var(--gray);
}

@media only screen and (max-width: 991px){
    #mobileApp .app-actions {
        margin-top: 15px;
    }
}

.inner-tab-controls .nav-item {
    margin-right: 30px;
}
.inner-tab-controls .nav-item:last-child{
    margin-right: 0;
}
.inner-tab-controls .nav-link {
    font-size: 22px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 0 0 10px;
    color: var(--white);
}
.inner-tab-controls .nav-link.active, .ninner-tab-controls .show>.nav-link {
    color: var(--green);
    background-color:transparent;
    border-bottom: 3px solid var(--green);
}
@media only screen and (max-width:1199px){
    .inner-tab-controls .nav-item {
        margin-right: 15px;
    }
    .inner-tab-controls .nav-link {
        font-size: 18px;
        border-bottom: 2px solid transparent;
    }
    .inner-tab-controls .nav-link.active, .ninner-tab-controls .show>.nav-link {
        border-bottom: 2px solid var(--green);
    }
}
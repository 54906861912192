#deal {
    max-width: 1030px;
    margin: auto;
    padding: 100px 0 50px;
}
#deal .slick-slide {
    padding: 40px 10px;
}

@media only screen and (max-width:991px){
    #deal {
        max-width: 100%;
    }
    #deal .container {
        max-width: 100%;
        padding: 0;
    }
    #deal .row {
       margin: 0;
    }
    #deal .col-12 {
        padding: 0;
    }
}
.pagination {
    justify-content: center;
    padding: 30px 0;
}
.page-item {
    margin-right: 10px;
}
.page-link {
    color: #1F6DE7;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-family: var(--noto);
    font-weight: 700;
    padding: 0 7px;
    outline: none !important;
}
.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
    z-index: 3;
    color: var(--default-color);
    background-color: transparent;
    border-color: transparent;
    outline: none !important;
    box-shadow: none ;
}
.next-prev {
    border: 1px solid var(--gray);
    display: inline-block;
    padding: 5px 20px;
    border-radius: 25px;
    margin-top: -25px;
    color: var(--gray);
}

#darkMode .page-item.active .page-link,
#darkMode .page-link:hover,
#darkMode .page-link:focus {
    color: var(--white);
}
@media only screen and (max-width: 767px){
    .page-item {
        margin-right: 5px;
    }
    .next-prev {
        padding: 5px 15px;
    }
    .page-link {
        font-size: 14px;
        padding: 0 5px;
    }
}